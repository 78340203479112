/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { getPrivateResource } from "../../services/api";

const DataProvider = (ConnectedComponent) => () => {
  const [data, setData] = useState({});
  const hotelID = localStorage.getItem("hotel_id") || false;

  useEffect(() => {
    let isV5api = true;
    async function getEnhancedData() {
        const langCode = localStorage.getItem('lang') || 'en';

      const {
        data: { data },
      } = await getPrivateResource(`/wash/laundry/guest/menu/${hotelID}?lang_code=${langCode}`,isV5api);

      const allItems = data.categories.map(cat => cat["without_sub_category_items"])
      const flattenedItems = allItems.flat()
      localStorage.setItem('laundryDis',data?.disclaimer)
      setData({ 
        items: data?.categories,
        disclaimer: data?.disclaimer,
        description: data?.description,
        view_only:  data.view_only,
        flattenedItems
       });
    }
    getEnhancedData();
  }, [hotelID]);

  return <ConnectedComponent {...data} />;
};

export default DataProvider;
