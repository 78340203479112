import React, { useState} from "react";
import DatePickerInstance from "react-mobile-datepicker";

const DatePicker = ({
  placeholder,
  config,
  saveTime,
  value = "",
  confirmText,
  cancelText,
  errorText,
  cType,
  minHour,
  maxHour,
}) => {
  const [open, toggleOpen] = useState(false);
  const [error, setError] = useState("");

  const saveTimeHandler = (time) => {
    setError(null);
    if (cType === "date") {
      toggleOpen(false);
      return saveTime(time.toISOString().split("T")[0]);
    }
    let hours = time.getHours();
    let minutes = time.getMinutes();
    if (
      minHour > hours ||
      maxHour < hours ||
      ((minHour == hours || maxHour == hours) && minutes > 0)
    ) {
      setError(errorText);
      saveTime(null);
      toggleOpen(false);
      return;
    }

    const addZero = (minute) => (minute < 10 ? "0" + minute : minute);
    toggleOpen(false);
    return saveTime(`${addZero(hours) + ":" + addZero(minutes)}`);
  };
  const getDate = () => {
    let startDay = new Date();
    if (cType == "hour") {
      startDay.setMinutes(0);
      startDay.setSeconds(35);
      return startDay;
    } else {
      return startDay;
    }
  };
  return (
    <>
      <div className='datePickerWrapper'>
        <div className="value-field" onClick={() => toggleOpen(!open)}>
          {error || value || placeholder}
        </div>

        <DatePickerInstance
          cType={cType}
          value={getDate()}
          isOpen={open}
          dateConfig={config}
          showCaption={true}
          theme="ios"
          onSelect={saveTimeHandler}
          onCancel={() => toggleOpen(false)}
          confirmText={confirmText}
          cancelText={cancelText}
          min={config.min}
          max={config.max}
        />
      </div>
    </>
  );
};

export default DatePicker;
