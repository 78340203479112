import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import { Button } from 'antd';
function BookingCard({ data }) {
    const navigate = useNavigate();
    const { t } = useLanguage();

    const navigateToCheckOut = (bookingData) => {
        localStorage.setItem('__sBooking__', JSON.stringify(bookingData));
        navigate('/confirmbooking');
    };

    return (
        <div className='serviceCard'>
            {data.image ? (
                <img
                    src={
                        'https://master-vserve.s3.ap-south-1.amazonaws.com/booking-services/' +
                        data.image
                    }
                    alt='service-img'
                />
            ) : null}
            <div className='card-details'>
                <div className='details'>
                    <div className='title'>{data?.name}</div>
                    <div
                        className='discription'
                        dangerouslySetInnerHTML={{ __html: data?.description }}
                    ></div>
                </div>
                <div className='actions'>
                    <Button
                        type='primary'
                        onClick={() => navigateToCheckOut(data)}
                        className='bg-theme'
                    >
                        {data.hotel_id == 217 || data.hotel_id == 199
                            ? t['reserve_btn']
                            : t['book_btn']}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default BookingCard;
