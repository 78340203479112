import React from "react";
import styles from "./button.module.css";

const Button = ({ type, label, onClick, disabled = false }) => {
  return (
    <button className={disabled? styles.disabled : styles[type]} onClick={onClick} disabled={disabled}>
      {label}
    </button>
  );
};

export default Button;
