import React from 'react';
import provider from './data.provider';
import styles from './connect.module.css';
import { Header } from '../../components/components';

const Page = ({ content = '' }) => {
    if (!content) {
        return <div></div>;
    }
    return (
        <>
            <Header showBack />
            <div className={styles.connectLayout}>
                <div
                    className={styles.connect}
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </div>
        </>
    );
};

export default provider(Page);
