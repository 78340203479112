import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Header, Loading } from '../../components/components';
import { getPrivateResource } from '../../services/api';
import BookingCard from './BookingCard';
import { useLanguage } from '../../context/LanguageContext';

function Bookings() {
    const [bookingsList, setbookingsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { t } = useLanguage();
    const hotelID = localStorage.getItem('hotel_id') || false;
    const userID = localStorage.getItem('__uid__') || '';
    const getAllServices = async () => {
        try {
            setLoading(true);
            const langCode = localStorage.getItem('lang') || 'en';
            const {
                data: { data },
            } = await getPrivateResource(
                `/booking-service/guest/services/${hotelID}?user_id=${userID}&lang_code=${langCode}`
            );
            setbookingsList(data);
            console.log(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };
    useEffect(() => {
        getAllServices();
    }, []);

    const getServiesList = () => {
        return (
            <>
                <div className='booking-header'>
                    <span onClick={() => navigate('/booking-history')}>
                        <i className='fa fa-history'></i> {t['my_booking_btn']}
                    </span>
                </div>
                {bookingsList.map((item, index) => {
                    return (
                        item.enabled === 1 && (
                            <BookingCard key={index} data={item} />
                        )
                    );
                })}
            </>
        );
    };
    return (
        <>
            <Header showBack />
            <div className='services-wrapper'>
                {loading ? (
                    <Loading />
                ) : bookingsList.length > 0 ? (
                    getServiesList()
                ) : (
                    <div className='text-center no-options'>
                        {t['no_service_found']}
                    </div>
                )}
            </div>
        </>
    );
}

export default Bookings;
