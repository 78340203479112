import React from 'react';
import { printCurrencySymbol } from '../../helpers/commonHelper';
import veg from '../../assets/icons/veg.png';
import nonveg from '../../assets/icons/non-veg.png';
import Modal from 'react-modal';
import { useHotel } from '../../context/HotelContext';
import { renderPrice } from '../../helper/commonHelper';
import { Counter } from '../../components/components';
import { useLanguage } from '../../context/LanguageContext';

const CheckoutAddonRepeatConfirm = (props) => {
    const { open, close, item, removeItem, duplicateItem, addNewAddon } = props;
    const { hotelDetails } = useHotel();
    const currentCurrency = printCurrencySymbol(hotelDetails);
    const { t } = useLanguage();

    return (
        <Modal
            isOpen={open}
            onRequestClose={close}
            className={'modal-dialog modal-sm addon-repeat-confirm'}
            overlayClassName={'modal-backdrop overflow-auto '}
        >
            <div className='modal-content' aria-modal='true'>
                <div className='modal-header'>
                    <div className='addon-item-title'>
                        {t['choose_preference']}
                    </div>
                    <button className='btn-icon' onClick={close}>
                        <i className='fa fa-times'></i>
                    </button>
                </div>
                <div className='modal-body'>
                    <div className='item-food-card'>
                        <div className='item-details-wrapper'>
                            <div className='item-title-wrapper'>
                                <div className='item-price'>
                                    <div className='item-title'>
                                        {item.type === 'veg' && (
                                            <div className='item-type'>
                                                <img src={veg} alt='veg_icon' />
                                            </div>
                                        )}
                                        {item.type === 'non_veg' && (
                                            <div className='item-type'>
                                                <img
                                                    src={nonveg}
                                                    alt='nonveg_icon'
                                                />
                                            </div>
                                        )}{' '}
                                        {item.itemName}
                                    </div>
                                    {item.itemPrice > 0 ? (
                                        <div className='item-price'>
                                            {currentCurrency}{' '}
                                            {renderPrice(
                                                item.itemPrice,
                                                hotelDetails
                                            )}
                                        </div>
                                    ) : (
                                        `  `
                                    )}
                                    {item.addons.length !== 0 ? (
                                        <ul className='selectedAddonList'>
                                            {item?.addons?.map((add) => {
                                                return (
                                                    <li>
                                                        <span
                                                            className='addon-name-wrapper'
                                                            dangerouslySetInnerHTML={{
                                                                __html: `${add.title}: ${add.name}`,
                                                            }}
                                                        ></span>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    ) : null}
                                </div>
                                <div className='item-actions'>
                                    <Counter
                                        count={item.itemCount}
                                        increment={() => duplicateItem(item)}
                                        decrement={() => removeItem(item)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='modal-footer'>
                    <div className='addon-item-footer'>
                        <button
                            className=' btn-new-customization'
                            onClick={() => addNewAddon()}
                        >
                            {t['add_new_customization']}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CheckoutAddonRepeatConfirm;
