import React from 'react';
import { BottomNav } from '../components/components';

const Layout = ({ children }) => {
    return (
        <>
            {children}
            <BottomNav />
        </>
    );
};

export default Layout;
