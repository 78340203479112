import React from 'react';
import { Header, Loading } from '../../components/components';
import provider from './data.provider';

import styles from './shield.module.css';

const Page = ({ content = '' }) => {
    if (!content) {
        return <Loading />;
    }
    return (
        <>
            <Header showBack />
            <div className={styles.shieldLayout}>
                <div
                    className={styles.shieldContent}
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </div>
        </>
    );
};

export default provider(Page);
