import React, { useState } from 'react';
import {Link, useNavigate } from 'react-router-dom'; 
import { Loading, Card } from '../../components/components';
import styles from './spa.module.css';
import provider from './data.provider';
import { SPA_IMAGE_ROOT_URL } from '../../services/api';
import { useEffect } from 'react';
import axios from 'axios';
import {Header} from '../../components/components';
import { useLanguage } from '../../context/LanguageContext';

const SpaCategoriesList = ({ items = [] }) => {
    const [spaData, setSpaData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const hotelID = localStorage.getItem('hotel_id') || false;
    const { t } = useLanguage();

    useEffect(() => {
        fetchSpaList();
    }, []);

    const fetchSpaList = async () => {
        try {
            setLoading(true);
            setError('');
            const langCode = localStorage.getItem('lang') || 'en';
            const response = await axios.get(
                `sanitarium/spa/guest/menu/${hotelID}?lang_code=${langCode}`
            );
            if (response.status === 200) {
                const data = response.data?.data || [];
                if (data.length) {
                    const enabledSpa =
                        data.filter((item) => item.enabled == 1) || [];
                    setSpaData(enabledSpa);
                }
            }
        } catch (error) {
            setError('Error while fetching! please try again.');
        } finally {
            setLoading(false);
        }
    };

    if (spaData.length === 1 && !loading && error == '') {
        navigate(`/orderspa/${spaData[0].id}`)
    }
    const getBanner = (item = false) =>
        item ? (
            <div key={item.id} className={styles.spacardsize}>
                <Link
                    to={{
                        pathname: `/orderspa/${item.id}`,
                    }}
                >
                    <Card
                        heading={item.name}
                        cover={SPA_IMAGE_ROOT_URL + 'spas/' + item.image}
                    />
                </Link>
            </div>
        ) : (
            <div />
        );
    return (
                <>
            {loading ? (
                <Loading />
            ) : error ? (
                <p>{error}</p>
            ) : spaData.length === 0 ? (
                <p>{t['no_spa_found']}</p>
            ) : (
                <>
                    <Header
                        showBack
                        backBtnHandler={() => navigate('/')}
                    />
                    <div className={styles.spatiles}>
                        {spaData.map((item) => getBanner(item))}
                    </div>
                </>
            )}
        </>
    );
};

export default provider(SpaCategoriesList);
