/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getPublicResource } from "../../services/api";

const DataProvider = (ConnectedComponent) => () => {
  const [data, setData] = useState({});
  let location = useLocation();
  const resource = location.pathname?.substr(1);
  const hotelID = localStorage.getItem("hotel_id") || false;

  useEffect(() => {
    async function getEnhancedData() {
        const langCode = localStorage.getItem('lang') || 'en';

      const {
        data: { data },
      } = await getPublicResource(`/guest/hotel/${resource}/${hotelID}?lang_code=${langCode}`);
      const contetnData = data.sections?.sort((a, b) => b.priority - a.priority) || []
      setData({ content: contetnData,banner: data.banner});
    }
    getEnhancedData();
  }, [hotelID]);
  return <ConnectedComponent {...data} />;
};

export default DataProvider;
