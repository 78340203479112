import getSymbolFromCurrency from "currency-symbol-map";

export const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const printCurrencySymbol = (hotelDetails = {}) => {
  let definedSymbol = hotelDetails?.hotel_config?.currency_symbol;
  if (definedSymbol) {
    return definedSymbol;
  }
  const currencyCode = hotelDetails.country?.currency_code?.toUpperCase();
  if (
    currencyCode === "AED" ||
    currencyCode === "FJD" ||
    currencyCode === "PHL" ||
    currencyCode === "SAR" ||
    currencyCode === "OMR"
  ) {
    return currencyCode;
  }
  return getSymbolFromCurrency(currencyCode);
};
