import React, { useEffect, useState } from 'react';
import { useHotel } from '../context/HotelContext';
import { useLanguage } from '../context/LanguageContext';
import { Input } from 'antd';
import { getVerifyRoomNo } from '../services/api';
import { EyeTwoTone, LoadingOutlined, UserOutlined } from '@ant-design/icons';

const RoomNumber = (props) => {
    const { setVerifiedRoomNumber } = props;
    const { hotelDetails } = useHotel();
    const { t } = useLanguage();
    const [isRoomNoInUrl, setIsRoomNoInUrl] = useState(true);
    const [roomNo, setRoomNo] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const room = localStorage.getItem('room_no');
        const v = localStorage.getItem('isRoomNoInUrl');
        setIsRoomNoInUrl(v === '1');
        if (room) {
            setRoomNo(room);
            setVerifiedRoomNumber(room);
        }
    }, []);

    const roomChangeHandler = async (value) => {
        try {
            setLoading(true);
            const response = await getVerifyRoomNo(value);
            if (response.status === 200) {
                localStorage.setItem('room_no', value);
                setRoomNo(value);
                setVerifiedRoomNumber(value);
            }
        } catch (error) {
            localStorage.removeItem('room_no', '');
            setRoomNo('');
            setVerifiedRoomNumber('');
            setError(t['valid_room_no_msg']);
            setTimeout(() => {
                setError('');
            }, 2000);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className={'flex  justify-between items-center gap-2'}>
            <label className={'flex-1'}>
                {hotelDetails.id === 211
                    ? t['villa_table_no']
                    : hotelDetails.id === 472
                    ? t['deck_chair_no']
                    : hotelDetails.id === 343
                    ? 'Room/Table no.'
                    : hotelDetails.id === 659
                    ? t['table_number']
                    : t['room_villa_no']}
            </label>
            {!isRoomNoInUrl ? (
                <div className='flex flex-col'>
                    <Input
                        onBlur={(e) => roomChangeHandler(e.target.value)}
                        placeholder={
                            hotelDetails.id === 211
                                ? t['villa_table_no']
                                : hotelDetails.id === 472
                                ? t['deck_chair_no']
                                : hotelDetails.id === 343
                                ? 'Room/Table no.'
                                : hotelDetails.id === 659
                                ? t['table_number']
                                : t['room_villa_no']
                        }
                        onChange={(e) => setRoomNo(e.target.value)}
                        value={roomNo}
                        className='roomNumberInput flex-1 w-44'
                        suffix={loading ? <LoadingOutlined /> : ''}
                    />
                    {error && (
                        <p className='text-red-400'>
                            <small>{error}</small>
                        </p>
                    )}
                </div>
            ) : (
                <label>{roomNo}</label>
            )}
        </div>
    );
};

export default RoomNumber;
