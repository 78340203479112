import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { Drawer, Input } from 'antd';
import { Button } from 'antd';

function FeedInterestDialog({
    modalIsOpen,
    onCancel,
    onConfirm,
    interestInputChangeHandler,
    interestFormData = {},
    loading,
    feedCollectInfoList = [],
}) {
    const { t } = useLanguage();

    const mappings = {
        name: {
            label: t['feed_guest_name_label'],
            type: 'text',
            name: 'guest_name',
            placeholder: t['feed_guest_name_placeholder'],
            valueKey: 'guest_name',
        },
        email: {
            label: t['feed_guest_email_label'],
            type: 'email',
            placeholder: t['feed_guest_email_placeholder'],
            name: 'guest_email',
            valueKey: 'guest_email',
        },
        mobile: {
            label: t['feed_guest_mobile_label'],
            type: 'text',
            placeholder: t['feed_guest_mobile_placeholder'],
            name: 'guest_mobile',
            valueKey: 'guest_mobile',
        },
        room_no: {
            label: t['feed_room_no_label'],
            type: 'text',
            name: 'room_no',
            placeholder: t['feed_guest_room_placeholder'],
            valueKey: 'room_no',
        },
    };
    return (
        <Drawer
            title={t['feed_please_provide_details']}
            className='h-48 feed-interest-dialog'
            onClose={onCancel}
            open={modalIsOpen}
            size={'default'}
            placement='bottom'
            height={'450px'}
        >
            <div className='main-content flex flex-col gap-2'>
                {feedCollectInfoList.map((item, index) => {
                    const property = mappings[item];
                    if (!property) return '';
                    return (
                        <div
                            className='form-group flex flex-col gap-2'
                            key={index}
                        >
                            <label className='label'>{property.label}</label>
                            <Input
                                type={property.type || 'text'}
                                name={property.name || ''}
                                placeholder={property.placeholder || ''}
                                value={
                                    interestFormData?.[property.valueKey] || ''
                                }
                                onChange={(e) => interestInputChangeHandler(e)}
                                className='h-12 border rounded-xl border-gray-300 p-3 flex-1'
                                required
                            />
                        </div>
                    );
                })}
            </div>
            <div div className='flex justify-center items-center gap-2 mt-4'>
                <Button onClick={onCancel} disabled={loading} size='medium' className='bg-theme'>
                    {t['common_cancel']}
                </Button>
                <Button
                    size='medium'
                    type='primary'
                    onClick={() => onConfirm()}
                    disabled={loading}
                    className='bg-theme'
                >
                    {loading ? (
                        <>
                            <i
                                className='fa fa-spinner fa-spin'
                                aria-hidden='true'
                                style={{ marginRight: '5px' }}
                            ></i>{' '}
                            {t['feed_submitting']}
                        </>
                    ) : (
                        t['common_confirm']
                    )}
                </Button>
            </div>
        </Drawer>
    );
}

export default FeedInterestDialog;
