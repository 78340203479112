import React, { useEffect, useState } from 'react';
import FeedCard from './FeedCard';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {
    getAllFeeds,
    likeFeed,
    UnlikeFeed,
    viewFeeds,
} from '../../services/api';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { serialize } from 'object-to-formdata';
import { useHotel } from '../../context/HotelContext';
import { Header } from '../../components/components';
const Feed = () => {
    const [loading, setLoading] = useState(false);
    const [feedList, setFeedList] = useState([]);
    const [muted, setMuted] = useState(true);
    const [isSkelton, setIsSkelton] = useState(true);

    const { loadMoreRef, page } = useInfiniteScroll();
    const { hotelDetails } = useHotel();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
        fetchAllFeeds(page);
    }, [page]);

    useEffect(() => {
        if (feedList.length > 0) {
            setTimeout(() => {
                playFirstVideo();
            }, 500);
        }
    }, [feedList]);

    const playFirstVideo = () => {
        if (!!window.IntersectionObserver) {
            const videos = document.querySelectorAll('video'); // Select ALL the Videos
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (!entry.isIntersecting) {
                            entry.target.pause(); // Pause the TARGET video
                        } else {
                            entry.target.play(); // Play the TARGET video
                        }
                    });
                },
                { threshold: 1 }
            );
            for (const video of videos) observer.observe(video); // Observe EACH video
            const onVisibilityChange = () => {
                if (document.hidden) {
                    for (const video of videos) video.pause(); // Pause EACH video
                } else {
                    for (const video of videos) video.play(); // Play EACH video
                }
            };
            document.addEventListener('visibilitychange', onVisibilityChange);
        }
    };

    const fetchAllFeeds = async (page, count = 10) => {
        try {
            setLoading(true);
            const response = await getAllFeeds(hotelDetails.id, page, count);
            if (response.status === 200) {
                const feed = response.data.data ?? [];
                console.log({ original: feed });
                setFeedList((prev) => [...prev, ...feed]);
                let ids = feed.map((feedItem) => feedItem.id) || [];
                if (ids.length > 0) increaseViewFeedCount({ feed_ids: ids });
                
            }
        } catch (error) {
        } finally {
            setLoading(false);
            setIsSkelton(false);
        }
    };
    const increaseViewFeedCount = async (obj) => {
        try {
            const data = serialize(obj);
            const response = await viewFeeds(data);
            if (response.status === 200) {
            }
        } catch (error) {
            console.log(error);
        } finally {
        }
    };

    const feedLikeCLickHandler = async (e, id, isLiked) => {
        if (e.target.classList && e.target.classList.contains('liked')) {
            e.target.classList.remove('liked');
        } else {
            e.target.classList.add('liked');
        }
        try {
            if (!isLiked) {
                const response = await likeFeed(id);
                if (response.status === 200) {
                }
            } else {
                const response = await UnlikeFeed(id);
                if (response.status === 200) {
                }
            }
            let updatedFeedlist = feedList.map((item) => {
                if (item.id === id) {
                    if (isLiked) {
                        return {
                            ...item,
                            likes_count: item.likes_count - 1,
                            is_liked_by_me: false,
                        };
                    } else {
                        return {
                            ...item,
                            likes_count: item.likes_count + 1,
                            is_liked_by_me: true,
                        };
                    }
                } else {
                    return { ...item };
                }
            });
            setFeedList(updatedFeedlist);
        } catch (error) {}
    };

    const muteToggleHandler = () => {
        setMuted((prevState) => !prevState);
    };
    return (
        <>
            <Header showBack />
            <div className='feed-wrapper'>
                <div className='feed-list-container'>
                    {isSkelton && !feedList.length ? (
                        <>
                            <div className='skelton-wrapper'>
                                <div className='skelton-profile-pic'></div>
                                <div className='skelton-img-big'></div>
                                <div className='skelton-description'></div>
                                <div className='skelton-description'></div>
                            </div>
                            <div className='skelton-wrapper'>
                                <div className='skelton-profile-pic'></div>
                                <div className='skelton-img-big'></div>
                                <div className='skelton-description'></div>
                                <div className='skelton-description'></div>
                            </div>
                            <div className='skelton-wrapper'>
                                <div className='skelton-profile-pic'></div>
                                <div className='skelton-img-big'></div>
                                <div className='skelton-description'></div>
                                <div className='skelton-description'></div>
                            </div>
                        </>
                    ) : feedList.length > 0 ? (
                        feedList.map((feed) => (
                            <FeedCard
                                key={feed.id}
                                feed={feed}
                                muted={muted}
                                muteToggleHandler={muteToggleHandler}
                                feedLikeCLickHandler={feedLikeCLickHandler}
                            />
                        ))
                    ) : null}
                </div>
                <div ref={loadMoreRef} style={{ textAlign: 'center' }}>
                    {loading && 'loading...'}
                </div>
            </div>
        </>
    );
};

export default Feed;
