import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading, Header } from '../../components/components';
import provider from './data.provider';

import styles from './reuse.module.css';

const Page = ({ content = '' }) => {
  const navigate = useNavigate();
  if (!content) {
    return <Loading />;
  }
  return (
    <>
    <Header
        showBack
    />
    <div className={styles.reuseLayout}>
      <div
        className={styles.reuse}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
    </>
  );
};

export default provider(Page);
