import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';
import store from './store/store';
import { Provider } from 'react-redux';

import './index.scss';
import './stylesheet/main.scss';

import HotelContextProvider from './context/HotelContext';
import LanguageContextProvider from './context/LanguageContext';
ReactGA.initialize('G-8JH41HEJKM');
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <HotelContextProvider>
                <LanguageContextProvider>
                    <App />
                </LanguageContextProvider>
            </HotelContextProvider>
        </Provider>
    </BrowserRouter>
);

serviceWorker.register();
