import React from 'react';
import Language from '../../components/Language';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const Page = () => {
    const navigate = useNavigate();

    return (
        <div className='flex flex-col flex-1 relative justify-center items-center min-h-screen z-10  bg-white gap-4 p-4'>
            <div className='font'>Choose your language</div>
            <div className='font'>
                Select your prefereed language to use this app easily
            </div>
            <Language />
            <Button onClick={() => navigate('/', { replace: true })} type='primary' className='bg-theme'>
                Continue
            </Button>
        </div>
    );
};

export default Page;
