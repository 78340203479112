import { configureStore } from "@reduxjs/toolkit";
import irdSlice from "./slices/irdSlice";

const store = configureStore({
  reducer: {
    irdSlice: irdSlice,
    devTools: process.env.NODE_ENV !== "production",
  },
});

export default store;
