import React from "react";
import styles from "./textArea.module.css";

const TextArea = ({ value, onChange, placeholder="" ,rows=4}) => {
  return (
    <textarea
      className={styles.textArea}
      rows={rows}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

export default TextArea;
