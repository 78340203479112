import React from 'react';
import { Button } from 'antd';
import {
    MinusOutlined,
    PlusOutlined,
} from '@ant-design/icons';
const ButtonGroup = Button.Group;
const Counter = ({ count, increment, decrement }) => {
    return (
        <ButtonGroup>
            <Button onClick={decrement} className='bg-theme' icon={<MinusOutlined />} />
            <Button icon={String(count)} />

            <Button onClick={increment} className='bg-theme' icon={<PlusOutlined />} />
        </ButtonGroup>
    );
};

export default Counter;
