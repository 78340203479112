import React, { useEffect, useState } from 'react';
import { WelcomeCard, Loading, Header } from '../../components/components';
import provider from './data.provider';
import ReactGA from 'react-ga4';
import { useLanguage } from '../../context/LanguageContext';
import { useNavigate } from 'react-router-dom';
import RenderContentView from './RenderContent';

const Welcome = ({ content = [], banner }) => {
    const { lang } = useLanguage();
    const navigate = useNavigate();
    const [contentView, setContentView] = useState(false);
    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
            language: lang,
        });
    }, []);

    if (!content.length) {
        return <Loading />;
    }
    const COLOR_WITH_TYPE = 'backgroud_color';

    const welcomeTileClickHandler = (section) => {
        if (section.has_subsections) {
            navigate(`/welcome-details?sectionid=${section.id}`);
        } else {
            setContentView(section.content);
        }
    };

    const closeClickHandler = () => {
        setContentView(null);
    };
    return (
        <>
            {!contentView && (
                <>
                    <Header showBack />
                    <div className={'welcome-wrapper'}>
                        {banner === 'NO_IMAGE' ? (
                            ''
                        ) : banner ? (
                            <img
                                src={`https://master-vserve.s3.ap-south-1.amazonaws.com/hotel_services/${encodeURIComponent(
                                    banner
                                )}`}
                                alt=''
                                className='welcome-cover-img'
                            />
                        ) : (
                            localStorage.getItem('__welcome_cover') && (
                                <img
                                    src={localStorage.getItem(
                                        '__welcome_cover'
                                    )}
                                    alt=''
                                    className='welcome-cover-img'
                                />
                            )
                        )}
                        {content.map((element) => (
                            <WelcomeCard
                                key={Math.random()}
                                color={
                                    element.backgroud_color
                                        ? element.backgroud_color
                                        : element[COLOR_WITH_TYPE]
                                }
                                textColor={element.font_color}
                                name={element.name}
                                description={element.description}
                                id={element.id}
                                onClick={() => welcomeTileClickHandler(element)}
                            />
                        ))}
                    </div>
                </>
            )}
            {contentView && (
                <RenderContentView
                    html={contentView}
                    closeClickHandler={closeClickHandler}
                />
            )}
        </>
    );
};

export default provider(Welcome);
