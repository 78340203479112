import React, { useState } from 'react';
import { Counter } from '../components';
import styles from './laundryCard.module.css';
import { printCurrencySymbol } from '../../helpers/commonHelper';
import { useLanguage } from '../../context/LanguageContext';
import { renderPrice } from '../../helper/commonHelper';
import { useHotel } from '../../context/HotelContext';
import { Button } from 'antd';
const LaundryCard = ({
    name,
    price,
    increaseQuantity,
    decreaseQuantity,
    defaultQuantity,
    view_only,
}) => {
    const [count, setCount] = useState(
        defaultQuantity?.[0]?.quantity > 1
            ? defaultQuantity?.[0]?.quantity
            : defaultQuantity?.length
    );
    const { hotelDetails } = useHotel();
    const { t } = useLanguage();

    const currentCurrency = printCurrencySymbol(hotelDetails);

    const increment = (newQuantity) => {
        setCount(newQuantity);
        increaseQuantity(newQuantity);
        return;
    };

    const decrement = (newQuantity) => {
        setCount(newQuantity);
        decreaseQuantity(newQuantity);
        return;
    };

    return (
        <article className='flex flex-row mb-2 relative text-md justify-between laundrycard border border-gray-300'>
            <div className={'flex flex-col'}>
                <div className='text-sm'>{name}</div>
                {!view_only && (
                    <span className='text-sm'>
                        {currentCurrency} {renderPrice(price, hotelDetails)}
                    </span>
                )}
            </div>
            <div className='flex flex-col'>
                <div className={styles.addButtonWrapper}>
                    {view_only && (
                        <span className='text-sm'>
                            {currentCurrency} {renderPrice(price, hotelDetails)}
                        </span>
                    )}
                    {!view_only && (
                        <>
                            {count === 0 ? (
                                <Button
                                    onClick={() => [setCount(1), increment(1)]}
                                >
                                    {t['ird_add_item_btn']}
                                </Button>
                            ) : (
                                <Counter
                                    count={count}
                                    increment={() => increment(count + 1)}
                                    decrement={() => decrement(count - 1)}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </article>
    );
};

export default LaundryCard;
