import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Header } from '../../components/components';

function StaticService(props) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();
    const location = useLocation();
    const serviceData = location.state?.data || '';

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, []);

    if (!serviceData) {
        navigate('/');
    }
    return (
        <>
            <Header showBack />
            <div className='static-content-container'>
                {serviceData && (
                    <div
                        className='content-wrapper'
                        dangerouslySetInnerHTML={{ __html: serviceData }}
                    ></div>
                )}
            </div>
        </>
    );
}

export default StaticService;
