import React, { useEffect, useState } from 'react';
import {
    addLocalCartItem,
    generateCartItem,
    renderPrice,
    updateLocalCartItem,
} from '../../helper/commonHelper';
import { printCurrencySymbol } from '../../helpers/commonHelper';
import { useHotel } from '../../context/HotelContext';
import { useLanguage } from '../../context/LanguageContext';
import { Button, Drawer } from 'antd';
import { Counter } from '../../components/components';

function AddonComponent(props) {
    const [itemAddon, setItemAddon] = useState([]);
    const { hotelDetails } = useHotel();
    const { menuItem, selectedCartItemID, open, close, updateOperationType } =
        props;
    const currentCurrency = printCurrencySymbol(hotelDetails);
    const { t } = useLanguage();

    useEffect(() => {
        populateCheckedFields();
    }, [itemAddon]);

    useEffect(() => {
        if (selectedCartItemID) {
            // Creating a temp object  for item, if user is modifying already selected item
            const selectedItem = menuItem.cartItems.filter(
                (item) => item.cartItemId === selectedCartItemID
            );
            let getItemAddon = JSON.parse(JSON.stringify(selectedItem[0]));
            setItemAddon(getItemAddon);
        } else {
            // Creating a temp Obj for new item
            let getItemAddon = generateCartItem(menuItem);
            setItemAddon(getItemAddon);
        }
    }, [menuItem, selectedCartItemID]);

    // get deepcopy of itemaddons
    function getDeepCopy() {
        return JSON.parse(JSON.stringify(itemAddon));
    }

    // add checked item property if count is > 1
    function populateCheckedFields() {
        const populateRadioItem = (subAdd) => {
            let selectedItemId = false;
            subAdd?.addons?.forEach((addon) => {
                if (addon.count > 0) {
                    selectedItemId = addon.id;
                }
            });
            subAdd.checkedItem = selectedItemId;
        };

        const populateCheckBoxAddon = (addons) => {
            addons?.forEach((addon) => {
                addon.isChecked = addon.count > 0;
            });
        };

        const subAddons = itemAddon?.sub_addons;
        subAddons?.forEach((subAdd) => {
            if (subAdd.type === 'multi_select') {
                populateCheckBoxAddon(subAdd.addons);
            } else {
                populateRadioItem(subAdd);
            }
        });
        const withoutSubAddons = itemAddon?.without_subaddon_addons;
        populateCheckBoxAddon(withoutSubAddons);
    }

    // handle checkbox with extra addon
    async function handleMultiSelect(event, item, subAddOn = null) {
        let itemdata = getDeepCopy();
        let checked = event.target.checked;

        if (checked) {
            // mark cliked item as checked
            itemdata.sub_addons.forEach((subAddOn) => {
                const { max_addon_per_order, addons } = subAddOn;
                for (const addon of addons) {
                    if (addon.id == item.id) {
                        addon.isChecked = checked;
                        addon.count = 1;
                    }
                }
            });
            itemdata.sub_addons.forEach((subAddOn) => {
                const { max_addon_per_order, addons } = subAddOn;
                for (const addon of addons) {
                    if (addon.id == item.id) {
                        if (addon.isChecked) {
                            if (max_addon_per_order < addons.length) {
                                //    Only allow to select checkbox if selected items doesn't exceed max item
                                const selectedItem = addons?.filter(
                                    (_item) => _item.isChecked
                                ).length;
                                if (selectedItem > max_addon_per_order) {
                                    const msg = `Max ${max_addon_per_order} can be selected `;
                                    addon.isChecked = false;
                                    addon.count = 0;
                                    return showToastMsg(msg);
                                }
                            }
                        }
                    }
                }
            });
        } else {
            // when user in unchecking the checkbox
            itemdata.sub_addons.forEach((subAddOn) => {
                const { max_addon_per_order, addons } = subAddOn;
                for (const addon of addons) {
                    if (addon.id == item.id) {
                        addon.isChecked = false;
                        addon.count = 0;
                    }
                }
            });
        }
        setItemAddon(itemdata);
    }

    async function showToastMsg(msg) {
        window.alert(msg);
    }

    function onItemAdd(item) {
        let itemData = getDeepCopy(itemAddon);
        itemData.sub_addons.find((subaddon) => {
            for (const addon of subaddon.addons) {
                if (addon.id == item.id) {
                    if (addon.count < addon.max_per_order) {
                        addon.count += 1;
                    } else {
                        const msg = `Max. ${addon.max_per_order} items allowed.`;
                        showToastMsg(msg);
                    }
                }
            }
        });
        setItemAddon(itemData);
    }

    function onItemRemove(item) {
        let itemData = getDeepCopy(itemAddon);
        itemData.sub_addons.find((subaddon) => {
            for (const addon of subaddon.addons) {
                if (addon.id == item.id) {
                    if (addon.count > 1) {
                        addon.count -= 1;
                    } else {
                        const msg = `Min. 1 item allowed.`;
                        showToastMsg(msg);
                    }
                }
            }
        });
        setItemAddon(itemData);
    }

    function selectedRadioAddon(e, sub) {
        const selectedItemId = e.target.value;
        let checked = e.target.checked;
        let itemAddonCopy = getDeepCopy();
        itemAddonCopy.sub_addons.find((subaddon) => {
            if (subaddon.id == sub.id) {
                for (const addon of subaddon.addons) {
                    if (addon.id == selectedItemId) {
                        addon.count = 1;
                        addon.checkedItem = checked;
                    } else {
                        addon.count = 0;
                    }
                }
            }
        });
        setItemAddon(itemAddonCopy);
    }

    function selectedCheckBoxAddon(event, item, subadon = null) {
        handleMultiSelect(event, item, subadon);
    }

    function getSelectedCount() {
        let count = 0;

        const updateCount = (addons) => {
            addons.forEach((addon) => {
                count += addon.count;
            });
        };

        itemAddon.sub_addons.forEach((subAdd) => {
            updateCount(subAdd.addons);
        });
        updateCount(itemAddon.without_subaddon_addons);

        return count;
    }

    function Addonsvalidation() {
        let AllAddons = [
            ...itemAddon?.sub_addons?.filter((item) => item.enabled === 1),
            ...itemAddon?.without_subaddon_addons?.filter(
                (item) => item.enabled === 1
            ),
        ];
        let totalAddonsCount = AllAddons.length;
        let tempCount = 0;
        for (let i = 0; i < AllAddons.length; i++) {
            const { addons } = AllAddons[i];
            for (let j = 0; j < addons?.length; j++) {
                const element = addons[j];
                if (element?.count) {
                    tempCount += 1;
                    break;
                }
            }
        }
        return totalAddonsCount === tempCount;
    }
    async function addAddOns() {
        const isAllselected = Addonsvalidation();
        // if(!isAllselected){

        // }
        // const count = getSelectedCount();
        // console.log(`${count} items selected`);

        if (isAllselected) {
            //    Item added to cart then dismiss the modal
            let operationType = '';

            if (selectedCartItemID) {
                const updatedCartMenuItems = menuItem.cartItems.map((item) => {
                    if (item.cartItemId === selectedCartItemID) {
                        item = itemAddon;
                    }
                    return item;
                });
                menuItem.cartItems = updatedCartMenuItems;
                updateLocalCartItem(itemAddon);
                operationType = 'update';
            } else {
                // If item is newly added
                menuItem.cartItems.push(itemAddon);
                addLocalCartItem(itemAddon);
                operationType = 'add';
            }
            updateOperationType(operationType);
            close();
        } else {
            // await showToastMsg("One or more choice is not selected *");
            await showToastMsg(t['ird_addon_alert']);
            // return;
        }
    }

    return (
        <Drawer
            className='h-48'
            onClose={close}
            open={open}
            size={'default'}
            placement='bottom'
            height={'450px'}
            title={itemAddon?.name}
        >
            <div className='flex flex-col justify-start gap-4 pb-16'>
                <div className='options-wrapper'>
                    {React.Children.toArray(
                        itemAddon?.sub_addons?.map((subAddon) => {
                            if (subAddon.enabled === 1) {
                                return (
                                    <div
                                        className='addon-main'
                                        key={subAddon.id}
                                    >
                                        {subAddon.type === 'single_select' ? (
                                            <>
                                                <div className='addon-item-header'>
                                                    <div className='addon-item-title'>
                                                        {subAddon.name}
                                                    </div>
                                                    {subAddon.description && (
                                                        <div
                                                            className='addon-item-descp'
                                                            dangerouslySetInnerHTML={{
                                                                __html: subAddon.description,
                                                            }}
                                                        ></div>
                                                    )}
                                                </div>
                                                <div className='addons-list'>
                                                    {React.Children.toArray(
                                                        subAddon?.addons.map(
                                                            (item) => {
                                                                if (
                                                                    item.enabled ===
                                                                    1
                                                                ) {
                                                                    return (
                                                                        <div
                                                                            className='subaddon-card'
                                                                            key={
                                                                                item.id
                                                                            }
                                                                        >
                                                                            <div className='name-wrapper gap-1'>
                                                                                <input
                                                                                    type='radio'
                                                                                    id={
                                                                                        item.id
                                                                                    }
                                                                                    name={
                                                                                        subAddon.id
                                                                                    }
                                                                                    value={
                                                                                        item.id
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        selectedRadioAddon(
                                                                                            e,
                                                                                            subAddon
                                                                                        )
                                                                                    }
                                                                                    // checked={subAddon.checkedItem}
                                                                                />
                                                                                <div>
                                                                                    <label
                                                                                        className='subaddon-title'
                                                                                        htmlFor={
                                                                                            item.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                    </label>
                                                                                    {item.price >
                                                                                    0 ? (
                                                                                        <div className='subaddon-price'>
                                                                                            {item.count >
                                                                                                1 &&
                                                                                            item.max_per_order >
                                                                                                1 ? (
                                                                                                <div>
                                                                                                    {
                                                                                                        currentCurrency
                                                                                                    }{' '}
                                                                                                    {renderPrice(
                                                                                                        item.price *
                                                                                                            item.count,
                                                                                                        hotelDetails
                                                                                                    )}
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div>
                                                                                                    {
                                                                                                        currentCurrency
                                                                                                    }{' '}
                                                                                                    {renderPrice(
                                                                                                        item.price,
                                                                                                        hotelDetails
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    ) : (
                                                                                        <>

                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            {item.count &&
                                                                            item.max_per_order >
                                                                                1 ? (
                                                                                <Counter
                                                                                    count={
                                                                                        item.count
                                                                                    }
                                                                                    increment={() =>
                                                                                        onItemAdd(
                                                                                            item
                                                                                        )
                                                                                    }
                                                                                    decrement={() =>
                                                                                        onItemRemove(
                                                                                            item
                                                                                        )
                                                                                    }
                                                                                />
                                                                            ) : null}
                                                                        </div>
                                                                    );
                                                                }
                                                            }
                                                        )
                                                    )}
                                                </div>
                                            </>
                                        ) : subAddon.type === 'multi_select' ? (
                                            <>
                                                <div className='addon-item-header'>
                                                    <div className='addon-item-title'>
                                                        {subAddon.name}
                                                    </div>
                                                    {subAddon.description && (
                                                        <div
                                                            className='addon-item-descp'
                                                            dangerouslySetInnerHTML={{
                                                                __html: subAddon.description,
                                                            }}
                                                        ></div>
                                                    )}
                                                </div>
                                                <div className='addons-list'>
                                                    {React.Children.toArray(
                                                        subAddon.addons.map(
                                                            (item) => {
                                                                if (
                                                                    item.enabled ===
                                                                    1
                                                                ) {
                                                                    return (
                                                                        <div
                                                                            className='subaddon-card'
                                                                            key={
                                                                                item.id
                                                                            }
                                                                        >
                                                                            <div className='name-wrapper gap-1'>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    id={
                                                                                        item.id
                                                                                    }
                                                                                    name={
                                                                                        item.name
                                                                                    }
                                                                                    checked={
                                                                                        item.isChecked
                                                                                    }
                                                                                    value={
                                                                                        item.id
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        selectedCheckBoxAddon(
                                                                                            e,
                                                                                            item,
                                                                                            subAddon
                                                                                        );
                                                                                    }}
                                                                                />
                                                                                <div>
                                                                                    <label
                                                                                        className='subaddon-title'
                                                                                        htmlFor={
                                                                                            item.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                    </label>
                                                                                    {item.price >
                                                                                    0 ? (
                                                                                        <div className='subaddon-price'>
                                                                                            {item.isChecked &&
                                                                                            item.max_per_order >
                                                                                                1 ? (
                                                                                                <div>
                                                                                                    {
                                                                                                        currentCurrency
                                                                                                    }{' '}
                                                                                                    {renderPrice(
                                                                                                        item.price *
                                                                                                            item.count,
                                                                                                        hotelDetails
                                                                                                    )}
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div>
                                                                                                    {
                                                                                                        currentCurrency
                                                                                                    }{' '}
                                                                                                    {renderPrice(
                                                                                                        item.price,
                                                                                                        hotelDetails
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    ) : (
                                                                                        <>

                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            {item.count &&
                                                                            item.max_per_order >
                                                                                1 ? (
                                                                                <Counter
                                                                                    count={
                                                                                        item.count
                                                                                    }
                                                                                    increment={() =>
                                                                                        onItemAdd(
                                                                                            item
                                                                                        )
                                                                                    }
                                                                                    decrement={() =>
                                                                                        onItemRemove(
                                                                                            item
                                                                                        )
                                                                                    }
                                                                                />
                                                                            ) : null}
                                                                        </div>
                                                                    );
                                                                }
                                                            }
                                                        )
                                                    )}
                                                </div>
                                            </>
                                        ) : null}
                                    </div>
                                );
                            }
                        })
                    )}

                    {React.Children.toArray(
                        itemAddon?.without_subaddon_addons?.map((item) => {
                            if (item.enabled === 1) {
                                return (
                                    <div
                                        className='subaddon-card'
                                        key={item.id}
                                    >
                                        <div className='name-wrapper gap-1'>
                                            <input
                                                type='checkbox'
                                                name={item.name}
                                                id={item.id}
                                                checked={item.isChecked}
                                                value={item.id}
                                                onChange={(e) =>
                                                    selectedCheckBoxAddon(
                                                        e,
                                                        item
                                                    )
                                                }
                                            />
                                            <div>
                                                <label
                                                    className='subaddon-title'
                                                    htmlFor={item.id}
                                                >
                                                    {item.name}
                                                </label>
                                                {item.price > 0 && (
                                                    <div className='subaddon-price'>
                                                        {item.isChecked &&
                                                        item.max_per_order >
                                                            1 ? (
                                                            <div>
                                                                {
                                                                    currentCurrency
                                                                }{' '}
                                                                {renderPrice(
                                                                    item.price *
                                                                        item.count,
                                                                    hotelDetails
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                {
                                                                    currentCurrency
                                                                }{' '}
                                                                {renderPrice(
                                                                    item.price,
                                                                    hotelDetails
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {item.count &&
                                        item.max_per_order > 1 ? (
                                            <Counter
                                                count={item.count}
                                                increment={() =>
                                                    onItemAdd(menuItem)
                                                }
                                                decrement={() =>
                                                    onItemRemove(menuItem)
                                                }
                                            />
                                        ) : null}
                                    </div>
                                );
                            }
                        })
                    )}
                </div>
            </div>
            <div className='flex justify-center items-center gap-2 mt-auto absolute bg-slate-100 p-2 py-3 w-full left-0 bottom-0'>
                <Button
                    onClick={() => addAddOns()}
                    type='primary'
                    className='w-full bg-theme'
                    size='large'
                >
                    {t['Add']}
                </Button>
            </div>
            <div className='modal-footer'>
                <div className='addon-item-footer'></div>
            </div>
        </Drawer>
    );
}

export default AddonComponent;
