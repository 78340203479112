import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading, TextArea } from '../../components/components';
import ConfirmDialog from '../../components/modal/ConfirmDialog';
import { getVerifyRoomNo, postPrivateResource } from '../../services/api';
import moment from 'moment';
import { useLanguage } from '../../context/LanguageContext';
import { useHotel } from '../../context/HotelContext';
import arrowBackIcon from '../../assets/icons/arrow-back-icon.svg';
import ReactGA from 'react-ga4';

const splitDate = (date) => {
    let dateArray = new Date(date).toDateString().split(' ');
    dateArray.splice(3, 1);
    if (dateArray.length) {
        let commaSepratedDate = dateArray.map((item, index) => {
            if (index == 0) {
                return item + ',';
            } else {
                return item;
            }
        });
        let finalDate = commaSepratedDate.join(' ');
        return finalDate;
    } else {
        return '';
    }
};
const getday = (day) => {
    const weekdays = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];
    return weekdays[day];
};
const BookingCheckout = () => {
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState('');
    const [boookingData, setboookingData] = useState([]);
    const [pickupSlot, setPickupSlot] = useState(moment(new Date()).format());
    const [selectedslot, setSelectedslot] = useState(null);
    const [selectedDayIndex, setSelectedDayIndex] = useState(0);
    const [selectedDay, setSelectedDay] = useState(getday(new Date().getDay()));
    const [noOfGuest, setNoOfGuest] = useState(1);
    const [guestLength, setGuestLength] = useState([1, 2, 3, 4]);
    const [isconfirm, setIsconfirm] = useState(false);
    const [isRoomNoInUrl, setisRoomNoInUrl] = useState(true);
    const [roomNo, setRoomNo] = useState('');
    const [description, setDescription] = useState('');
    const [dayList, setdayList] = useState([]);
    const navigate = useNavigate();
    const { t,lang } = useLanguage();
    const { hotelDetails } = useHotel();

    const hotelID = localStorage.getItem('hotel_id') || false;
    const selectedBooking = JSON.parse(localStorage.getItem('__sBooking__'));

    if (!selectedBooking) {
        navigate('/booking');
    }

    useEffect(() => {
        let list = [];
        let dayLength = 7;
        for (let i = 0; i < dayLength; i++) {
            let newDate;
            newDate = new Date();
            const tomorrowDate = newDate.getDate() + i;
            newDate.setDate(tomorrowDate);
            const dayIndex = newDate.getDay();
            const dayObj = {
                id: i,
                day: getday(dayIndex),
                dayDate: splitDate(newDate),
            };
            list.push(dayObj);
        }
        // if (selectedBooking?.hotel_id === 60) {
        //   let removedFistDay = list.slice(1, list.length);
        //   setdayList(removedFistDay);
        // } else {
        setdayList(list);
        // }
    }, []);

    const getSlots = (day, id) => {
        setSelectedslot(null);
        const activeBooking = JSON.parse(localStorage.getItem('__sBooking__'));

        const selectedData = activeBooking?.booking_service_opening_days?.find(
            (item) => item.day == day
        );
        let sortedData = selectedData.booking_service_opening_day_timings.sort(
            (a, b) => {
                let aStart = a.opening_time.split(':')[0];
                let bStart = b.opening_time.split(':')[0];
                return aStart - bStart;
            }
        );
        if (sortedData) {
            setboookingData(sortedData);
        }
        setSelectedDay(day);
        setSelectedDayIndex(id);
        if (dayList.length) {
            dayList.forEach((item, index) => {
                if (item.id == id) {
                    let newDate;
                    newDate = new Date();
                    const tomorrowDate = newDate.getDate() + id;
                    newDate.setDate(tomorrowDate);
                    setPickupSlot(moment(newDate).format());
                }
            });
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        const v = localStorage.getItem('isRoomNoInUrl');
        setisRoomNoInUrl(v === '1');
        const savedRoom = localStorage.getItem('room_no');
        if (savedRoom) {
            setRoomNo(savedRoom);
        }
        let countlength = selectedBooking.adult_count || 4;
        let guestArr = [];
        for (let i = 1; i <= countlength; i++) {
            guestArr.push(i);
        }

        setGuestLength(guestArr);
    }, []);
    useEffect(() => {
        getSlots(selectedDay, selectedDayIndex);
    }, [selectedDayIndex, noOfGuest]);

    const converTime = (time) => {
        if (time) {
            let covertedTime = time;
            let H = +covertedTime.substr(0, 2);
            let h = H % 12 || 12;
            h = h < 10 ? '0' + h : h;
            let ampm = H < 12 ? ' AM' : ' PM';
            covertedTime = h + covertedTime.substr(2, 3) + ampm;
            return covertedTime;
        } else {
            return time;
        }
    };

    const userSelectedData = () => {
        // get selected slot
        if (selectedslot) {
            const slotData = boookingData?.find(
                (slot) => slot.id == selectedslot
            );
            // user selected date
            const userDate = dayList.find(
                (item) => item.id == selectedDayIndex
            );
            return (
                userDate.dayDate +
                '<br>' +
                converTime(slotData?.opening_time) +
                '-' +
                converTime(slotData?.closing_time)
            );
        } else {
            return 'No slots Selected';
        }
    };

    const verifyRoomNo = () => {
        getVerifyRoomNo(roomNo)
            .then((res) => {
                const r = res?.data;
                setIsError('');
            })
            .catch((err) => {
                const e = err?.response?.status;
                if (e === 404) {
                    setRoomNo(null);
                    window.scrollTo(0, 0);
                    setIsError(t['valid_room_no_msg']);
                }
            })
            .finally((e) => console.log(e));
    };
    const locationBack = () => {
        navigate(-1);
    };
    const placeOrder = async () => {
        setLoading(true);
        if (!roomNo) {
            setIsError('Please Enter room Number');
        }
        const data = {
            enc_hotel_id: hotelID,
            room_no: roomNo,
            no_of_guest: noOfGuest,
            booking_service_id: selectedBooking.id,
            booking_timing_id: selectedslot,
            pickup_slot: pickupSlot,
            description: description,
        };
        try {
            const res = await postPrivateResource(
                `/booking-service/guest/order`,
                JSON.stringify(data)
            );
            ReactGA.set({ bookingOrderPlaced: true });
            ReactGA.event({
                category: 'Booking',
                action: 'Booking Order Placed',
            });
            ReactGA.event({
                category: 'Booking',
                action: 'booking_order_placed',
                label: lang,
            });
            setIsconfirm(false);
            setLoading(false);
            return navigate('/thank-you');
        } catch (e) {
            console.log(e);
            let { data } = e?.response?.data;
            setIsconfirm(false);
            setLoading(false);
            setIsError(
                t['common_error']
            );
            if (data && data.booking_timing_id) {
                replaceAvailableBooking(data);
            }
        }
    };
    const checkSlotTime = (slotTime) => {
        if (selectedDayIndex == 0) {
            const time = moment().format('HH:mm:ss');
            const currentTime = moment(time, 'HH:mm:ss');
            const curretSlotTime = moment(slotTime, 'HH:mm:ss');
            return curretSlotTime.isBefore(currentTime);
        }
        return false;
    };

    const replaceAvailableBooking = (data) => {
        const selectedBooking = JSON.parse(
            localStorage.getItem('__sBooking__')
        );
        if (data && data.booking_timing_id) {
            selectedBooking.booking_service_opening_days.forEach((itemList) => {
                itemList.booking_service_opening_day_timings.forEach(
                    (slotData) => {
                        if (slotData.id === data.booking_timing_id) {
                            slotData.left_slot = data?.left_slot;
                            setIsError(
                                `Only ${data?.left_slot} slots are avaible for this booking!`
                            );
                            setTimeout(() => {
                                setIsError('');
                            }, 4000);
                        }
                    }
                );
            });
        }
        localStorage.setItem('__sBooking__', JSON.stringify(selectedBooking));
        getSlots(selectedDay, selectedDayIndex);
    };

    const getTime = () => {
        return (
            <div className='days flex flex-col w-full gap-2'>
                <div className='slots py-1'>
                    {dayList?.map(({ day, id, dayDate }) => {
                        return (
                            <div
                                key={id}
                                className={
                                    'day ' +
                                    (id === selectedDayIndex ? 'selected' : '')
                                }
                                onClick={() => getSlots(day, id)}
                            >
                                {day}
                                <div>
                                    <small>{dayDate}</small>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className='slots'>
                    <label className='flex-1'>
                        {selectedBooking?.hotel_id === 60
                            ? t['checkout_booking_no_of_adult_and_children']
                            : t['checkout_booking_no_of_guest']}
                    </label>
                    <select
                        value={noOfGuest}
                        onChange={(e) => setNoOfGuest(e.target.value)}
                        className='noofguest flex-1'
                    >
                        {guestLength.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='flex  justify-between items-center gap-1 w-full'>
                    <label className='flex-1'>
                        {t['checkout_booking_room_villa_no']}
                    </label>
                    {!isRoomNoInUrl ? (
                        <input
                            type='text'
                            value={roomNo}
                            onChange={(e) => setRoomNo(e.target.value)}
                            onBlur={(e) => verifyRoomNo()}
                            className='roomNumberInput flex-1'
                            placeholder='Room number'
                        />
                    ) : (
                        <p className='label room_Text'>{roomNo}</p>
                    )}
                </div>
            </div>
        );
    };

    const slotStatus = (time) => {
        if (!time) return '';
        let slotgap = hotelDetails.id === 47 ? 30 : 60;
        if (getday(new Date().getDay()) === selectedDay) {
            let gaptimer = slotgap * 60 * 1000;
            let splitedTime = time.split(':');
            let dateTime = new Date().setHours(
                splitedTime[0],
                splitedTime[1],
                splitedTime[2]
            );
            if (dateTime < new Date().getTime() + gaptimer) {
                return 'hide';
            }
        }
        return '';
    };
    return (
        <>
            <div className='checkout-header px-4 py-2 border-b'>
                {/* <Button
                    shape='circle'
                    icon={<ArrowLeftOutlined />}
                    className='shadow-sm'
                    onClick={() => locationBack()}
                ></Button> */}

                {/* <span
                    className='material-symbols-outlined text-gray-500 '
                    style={{ fontSize: '20px' }}
                    onClick={() => locationBack()}
                >
                    arrow_back_ios
                </span> */}
                <div
                    className='arrow-back-icon'
                    onClick={
                        () => locationBack()
                    }
                >
                    <img 
                        src={arrowBackIcon} 
                        alt="Language" 
                        className="language-icon"
                    />
                </div>

                <div className='service-title'>{selectedBooking?.name}</div>
            </div>
            <div className='service-checkout-wrapper flex flex-col gap-2'>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {getTime()}
                        <div className='description_wrapper'>
                            <label className='flex-1'>
                                {hotelDetails.id === 60
                                    ? t['checkout_booking_dietary_restrictions']
                                    : t['checkout_booking_remarks']}
                            </label>
                            <TextArea
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                            />
                        </div>
                        <div className='time-slots'>
                            <div className='slots-title'>
                                {t['checkout_booking_choose_slot']}
                            </div>
                            {boookingData.length === 0 ? (
                                <div style={{ textAlign: 'text-center' }}>
                                    <small>
                                        <b style={{ color: 'red' }}>
                                            {
                                                t[
                                                    'checkout_booking_slots_unavailable'
                                                ]
                                            }
                                        </b>
                                    </small>
                                </div>
                            ) : (
                                boookingData.length > 0 &&
                                boookingData.map((slot) => (
                                    <div
                                        key={slot.id}
                                        className={
                                            'slot ' +
                                            (slot.id === selectedslot
                                                ? 'selected '
                                                : '') +
                                            (slot.max_request &&
                                            slot.max_request <
                                                slot.total_active_guest_count
                                                ? 'full '
                                                : noOfGuest > slot.left_slot
                                                ? 'full '
                                                : slot.is_slot_booked_by_me
                                                ? 'full '
                                                : checkSlotTime(
                                                      slot.opening_time
                                                  )
                                                ? 'full '
                                                : '') +
                                            slotStatus(slot.opening_time)
                                        }
                                        onClick={() => setSelectedslot(slot.id)}
                                    >
                                        {converTime(slot.opening_time)} -{' '}
                                        {converTime(slot.closing_time)}
                                        {slot?.max_request ? (
                                            <div>
                                                <small>
                                                    {
                                                        t[
                                                            'checkout_booking_available'
                                                        ]
                                                    }{' '}
                                                    {slot.left_slot}
                                                </small>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                ))
                            )}
                        </div>
                        <div className='footer-btn-wrapper'>
                            {isError && <p className='error'>{isError}</p>}
                            <button
                                className={
                                    'confirm-book-btn ' +
                                    (!selectedslot || !roomNo ? 'hidden' : 'bg-theme')
                                }
                                onClick={() => setIsconfirm(true)}
                            >
                                {t['checkout_booking_confirm_and_book_btn']}
                            </button>
                        </div>
                        {isconfirm && (
                            <ConfirmDialog
                                modalIsOpen={isconfirm}
                                onCancel={() => setIsconfirm(false)}
                                onConfirm={placeOrder}
                                confirmMsg={`${
                                    t['checkout_booking_confirm_booking']
                                } <br><br> ${userSelectedData()} <br> ${
                                    t['checkout_no_of_guests']
                                }: ${noOfGuest}`}
                                disclaimerMsg={selectedBooking.disclaimer}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default BookingCheckout;
