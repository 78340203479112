import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Header, Loading } from '../../components/components';
import { getPrivateResource, postPrivateResource } from '../../services/api';
import { useLanguage } from '../../context/LanguageContext';

const converTime = (time) => {
    if (time) {
        let covertedTime = time;
        let H = +covertedTime.substr(0, 2);
        let h = H % 12 || 12;
        h = h < 10 ? '0' + h : h;
        let ampm = H < 12 ? ' AM' : ' PM';
        covertedTime = h + covertedTime.substr(2, 3) + ampm;
        return covertedTime;
    } else {
        return time;
    }
};
function BookingHistory() {
    const [loading, setLoading] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const navigate = useNavigate();
    const { t } = useLanguage();
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    const getUserHistory = async () => {
        try {
            setLoading(true);
            const langCode = localStorage.getItem('lang') || 'en';
            const {
                data: { data },
            } = await getPrivateResource(`/booking-service/guest/orders?lang_code=${langCode}`);
            if (data.data.length) {
                const sortedData = data.data.sort(
                    (a, b) => +new Date(b.created_at) - +new Date(a.created_at)
                );
                console.log(sortedData);
                setHistoryData(sortedData);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };
    useEffect(() => {
        getUserHistory();
    }, []);

    const cancelBooking = async (id) => {
        let remark = prompt(
            t['booking_cancellation_reason']
        );
        if (!remark)
            return alert(t['booking_cancellation_remark']);
        try {
            const data = {
                order_id: id,
                remark: remark,
            };
            const res = await postPrivateResource(
                `/booking-service/guest/order/cancel`,
                JSON.stringify(data)
            );
            console.log(res);
        } catch (error) {
            console.log(error);
        } finally {
            getUserHistory();
        }
    };
    return (
        <>
            <Header showBack />
            <div className='history-wrapper'>
                <div className='history-container'>
                    {loading ? (
                        <Loading />
                    ) : historyData.length > 0 ? (
                        historyData.map((item, index) => {
                            return (
                                <div className={`history_card ${item.status}`}>
                                    <div className='order-id'>
                                        {t['order_id']}: #
                                        {item.order_detail.order_id}
                                    </div>
                                    <div className='service-title'>
                                        {
                                            item.order_booking_services[0]
                                                .booking_service_name
                                        }
                                    </div>
                                    <div className='service-guest-count'>
                                        {t['checkout_booking_no_of_guest']}:{' '}
                                        <span>{item.no_of_guest}</span>
                                    </div>

                                    <div className='service-date'>
                                        {t['date']}:{' '}
                                        <span>
                                            {new Date(
                                                item.order_detail.pickup_slot
                                            ).toLocaleDateString(
                                                'en-US',
                                                options
                                            )}
                                        </span>
                                    </div>
                                    <div className='service-time'>
                                        {t['slot']}:{' '}
                                        <span>
                                            {converTime(
                                                item.order_detail.booking_slot.split(
                                                    '-'
                                                )[0]
                                            )}{' '}
                                            -{' '}
                                            {converTime(
                                                item.order_detail.booking_slot.split(
                                                    '-'
                                                )[1]
                                            )}
                                        </span>
                                    </div>
                                    <div className='service-actions'>
                                        <button
                                            className={
                                                ' btn-cancel ' +
                                                (+new Date() >
                                                +new Date(item.pickup_slot)
                                                    ? 'disabled'
                                                    : '')
                                            }
                                            onClick={() =>
                                                cancelBooking(item.id)
                                            }
                                        >
                                            {item.status === 'canceled'
                                                ? t['cancelled']
                                                : t['cancel_order']}
                                        </button>
                                        {/* <button className="btn btn-reschedule">Reschedule</button> */}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className='text-center no-options'>
                            {t['no_bookings_found']}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default BookingHistory;
