import React from 'react';

const WelcomeCard = ({ color, textColor, name, description, onClick }) => {
    const textStyle = {
        color: textColor ? textColor : '#1b1b19',
    };
    return (
        <article
            onClick={onClick}
            className={'welcome-card-new'}
            style={{ backgroundColor: color }}
        >
            <h4 style={textStyle} className='welcomeCard-heading'>
                {name}
            </h4>
            {description && (
                <p
                    className='welcome-description'
                    style={textStyle}
                    dangerouslySetInnerHTML={{ __html: description }}
                ></p>
            )}
        </article>
    );
};

export default WelcomeCard;
