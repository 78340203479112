import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    SpaCard,
    Loading,
    Disclaimer,
    Header,
    Menu,
    ModalTNC,
} from '../../components/components';
import styles from './spa.module.css';
import { SPA_IMAGE_ROOT_URL } from '../../services/api';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { useLanguage } from '../../context/LanguageContext';
import { Button } from 'antd';

const Spa = (props) => {
    const navigate = useNavigate();
    let { spaID } = useParams();
    const [showTnC, setShowTnC] = useState(false);
    const [selecteSpaItems, setselecteSpaItems] = useState(null);
    const [menu, toggleMenu] = useState(false);

    const [selectedMenu, setSelectedMenu] = useState({});
    const [spaData, setSpaData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const hotelID = localStorage.getItem('hotel_id') || false;
    const { t, lang} = useLanguage();

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
            language: lang,
        });
    }, []);

    useEffect(() => {
        fetchSpaList();
    }, []);

    const fetchSpaList = async () => {
        try {
            const langCode = localStorage.getItem('lang') || 'en';
            const response = await axios.get(
                `sanitarium/spa/guest/menu/${hotelID}?lang_code=${langCode}`
            );
            if (response.status === 200) {
                const data = response.data?.data || [];
                if (data.length) {
                    const enabledSpa =
                        data.filter((item) => item.enabled == 1) || [];
                    const selectedMenuActive = enabledSpa.find(
                        (item) => item.id == spaID
                    );
                    setSpaData(enabledSpa);
                    if (!selectedMenuActive) {
                        navigate('/', { replace: true });
                        return;
                    }
                    setSelectedMenu(selectedMenuActive);
                }
            }
        } catch (error) {
            setError('Error while fetching! please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selecteSpaItems) {
            const spaTime = {
                open_time: selectedMenu?.open_time,
                close_time: selectedMenu?.close_time,
            };
            if (spaTime) {
                selecteSpaItems['spaTime'] = spaTime;
            }
            if (selectedMenu.disclaimer) {
                setShowTnC(true);
            } else {
                redirectToCheckOutSpa();
            }
        }
    }, [selecteSpaItems]);

    const locationBack = () => {
        if (spaData?.length === 1) {
            navigate('/');
        } else {
            navigate('/spa');
        }
    };

    const checkOutSpaItems = (entry) => {
        setselecteSpaItems(entry);
    };
    const redirectToCheckOutSpa = () => {
        navigate('/checkout-spa', { state: { selectedItem: selecteSpaItems } });
    };

    const renderSpas = () => {
        return selectedMenu.categories?.map((category) => {
            return (
                category.enabled === 1 && (
                    <div className='spaService' key={Math.random()}>
                        {category?.image ? (
                            <>
                                <img
                                    className={styles.banner}
                                    src={
                                        SPA_IMAGE_ROOT_URL +
                                        'spa-categories/' +
                                        category.image
                                    }
                                    alt='spa_categories'
                                    style={{ width: '100%' }}
                                />
                            </>
                        ) : (
                            ''
                        )}

                        <div className='cat-name-wrapper'>
                            <p className='cat-name' id={category.id}>
                                {category.name}
                            </p>
                        </div>
                        {category.description && (
                            <p
                                className='category-description'
                                dangerouslySetInnerHTML={{
                                    __html: category.description,
                                }}
                            ></p>
                        )}

                        {category['sub_categories'].map((sub_categories) => {
                            return (
                                sub_categories.enabled === 1 && (
                                    <div
                                        className='spaService'
                                        key={Math.random()}
                                    >
                                        {sub_categories?.image ? (
                                            <>
                                                <img
                                                    className={styles.banner}
                                                    src={
                                                        SPA_IMAGE_ROOT_URL +
                                                        'spa-sub-categories/' +
                                                        sub_categories.image
                                                    }
                                                    alt='spa_subcategories'
                                                    style={{ width: '100%' }}
                                                />
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        <div className='cat-name-wrapper sub-cat'>
                                            <p className='cat-name'>
                                                {sub_categories.name}
                                            </p>
                                        </div>
                                        {sub_categories.description && (
                                            <p
                                                className='category-description'
                                                dangerouslySetInnerHTML={{
                                                    __html: sub_categories.description,
                                                }}
                                            ></p>
                                        )}
                                        {/* <span></span> */}
                                        {sub_categories.items.map(
                                            (sub_items) =>
                                                sub_items.enabled === 1 && (
                                                    <SpaCard
                                                        {...sub_items}
                                                        key={Math.random()}
                                                        onClick={() =>
                                                            checkOutSpaItems(
                                                                sub_items
                                                            )
                                                        }
                                                        view_only={Boolean(
                                                            selectedMenu.view_only
                                                        )}
                                                    />
                                                )
                                        )}
                                    </div>
                                )
                            );
                        })}

                        {category['without_sub_category_items'].map(
                            (entry) =>
                                entry.enabled === 1 && (
                                    <SpaCard
                                        {...entry}
                                        key={Math.random()}
                                        onClick={() => checkOutSpaItems(entry)}
                                        view_only={Boolean(
                                            selectedMenu.view_only
                                        )}
                                    />
                                )
                        )}
                    </div>
                )
            );
        });
    };
    const menuCategogries = selectedMenu.categories
        ?.filter((item) => Boolean(item.enabled))
        ?.map((category) => ({
            name: category.name,
            id: category.id,
        }));
    return (
        <>
            {loading ? (
                <Loading />
            ) : error ? (
                <p>{error}</p>
            ) : selectedMenu.categories?.length === 0 ? (
                <p>{t['no_spa_found']}</p>
            ) : (
                <>
                    <Header
                        disclaimerText={selectedMenu.disclaimer}
                        showBack
                        popupData={selectedMenu.disclaimer}
                        backBtnHandler={() => locationBack()}
                    />
                    <div className={"spaLayout-container " + styles.spaLayout}>
                        {menuCategogries?.length > 2 && (
                            <div className={styles.menuButtonWrapper}>
                                {menu && (
                                    <>
                                        <div
                                            className={styles.overlay}
                                            onClick={() => toggleMenu(false)}
                                            id='Adesh1'
                                        ></div>
                                        <div
                                            className={styles.menuWrapper}
                                            style={{
                                                height:
                                                    menuCategogries.length > 7
                                                        ? '35vh'
                                                        : `${menuCategogries.length}` *
                                                              32 +
                                                          4 +
                                                          'px',
                                            }}
                                        >
                                            <Menu
                                                subcategories={menuCategogries}
                                                onClose={() =>
                                                    toggleMenu(false)
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                                <Button onClick={() => toggleMenu(!menu)} type='primary'
                                    className='menu-fixed-btn bg-theme'
                                    >
                                    {t['spa_menu']}
                                </Button>
                            </div>
                        )}

                        {selectedMenu.description && (
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: selectedMenu.description,
                                }}
                                className='category-description'
                            ></p>
                        )}

                        {renderSpas()}
                    </div>

                    <ModalTNC
                        title={t['spa_terms_and_conditions']}
                        body={selectedMenu.disclaimer}
                        btnSuccess={t['success_btn']}
                        btnBack={t['back_btn']}
                        onSuccess={() => {
                            navigate('/checkout-spa', {
                                state: { selectedItem: selecteSpaItems },
                            });
                        }}
                        onClose={() => setShowTnC(false)}
                        open={showTnC}
                        popupHeight={'450px'}
                    />
                </>
            )}
        </>
    );
};

export default Spa;
