import React from "react";
import { Link } from "react-scroll";


const Menu = ({ subcategories, onClose }) => {
  return (
    <div className={`menu-fix menu`}>
      {subcategories.map(subcategory => (
        <Link
          to={`${subcategory.id}`}
          key={subcategory.id}
          spy={true}
          smooth={true}
          offset={-160}
          duration={500}
        >
          <p
            className={'menuParagraph'}
            onClick={onClose}
            key={subcategory.id}
          >
            {subcategory.name}
          </p>
        </Link>
      ))}
    </div>
  );
};

export default Menu;
