import React from 'react';
import { useHotel } from '../context/HotelContext';
import { useLanguage } from '../context/LanguageContext';
import { Button } from 'antd';
const Signature = (props) => {
    const { signaturePad } = props;
    const { hotelDetails = {} } = useHotel();
    const { t } = useLanguage();

    return (
        <div className='flex gap-2 flex-col'>
            <div className='flex flex-row w-full justify-between items-center'>
                {hotelDetails.id === 368 ? (
                    <label>
                        Please make a manual tick mark (✔️) below in the field
                        given and also tick the delivery acknowledgement box.
                    </label>
                ) : (
                    <label>
                        {hotelDetails.id === 418
                            ? 'Please Sign Below  وقع أدناه من فضلك'
                            : t['checkout_ird_food_signature']}
                    </label>
                )}
            </div>
            <div className='relative'>
                <canvas id='signature-canvas' className='sign-pad' />
                <Button onClick={() => signaturePad.clear()} danger type="text" className='absolute bottom-0 right-0 '>
                    {t['checkout_ird_clear_signature']}
                </Button>
            </div>
        </div>
    );
};

export default Signature;
