import { serialize } from 'object-to-formdata';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Carousel } from 'react-responsive-carousel';
import FeedInterestDialog from '../../components/modal/FeedInterestDialog';
import { feedInterest } from '../../services/api';
import { useLanguage } from '../../context/LanguageContext';
import { useHotel } from '../../context/HotelContext';

const FeedCard = (props) => {
    const { feed = {}, muted, feedLikeCLickHandler } = props;
    let roomNumber = localStorage.getItem("room_no") || "";
    const [openInterestDialog, setOpenInterestDialog] = useState(false);
    const [interestFormData, setInterestFormData] = useState({
        feed_id: null,
        room_no: roomNumber || ""
    });
    const [loading, setLoading] = useState(false);
    const { hotelDetails = {} } = useHotel();
    const {t}=useLanguage();

    let feedCollectInfoList = [];
    let getNodeList = hotelDetails?.hotel_term?.find(
        (item) => item.type === 'feed_collect_info'
    );
    if (getNodeList && getNodeList.term) {
        feedCollectInfoList = JSON.parse(getNodeList.term);
    }
    const onFeedChange = (index, item) => {
        let type = item.props['data-content-type'];
        let id = item.props['data-content-id'];
        pauseAllRunningVideo();
        if (type === 'video') {
            const getVideo = document.querySelector(
                `[data-content-id="${id}"]`
            );
            const video = getVideo.querySelector('video');
            if (video) {
                video.play();
            }
        }
    };
    const pauseAllRunningVideo = () => {
        const getAllItems = document.querySelectorAll('.feed-content');
        if (getAllItems.length) {
            getAllItems.forEach((item) => {
                const video = item.querySelector('video');
                if (video) {
                    video.pause();
                }
            });
        }
    };

    const onFeedClick = (index, item) => {
        let type = item.props['data-content-type'];
        let id = item.props['data-content-id'];
        if (type === 'video') {
            const getVideo = document.querySelector(
                `[data-content-id="${id}"]`
            );
            const video = getVideo.querySelector('video');
            if (video) {
                if (video.paused) {
                    video.play();
                } else {
                    video.pause();
                }
            }
        }
    };
    const onMuteClickHandler = (e, id) => {
        e.stopPropagation();
        // const getVideo = document.querySelector(
        //     `[data-content-id="video-${id}"]`
        // );
        // const video = getVideo.querySelector('video');
        // if (e.target.classList && e.target.classList.contains('muted')) {
        //     e.target.classList.remove('muted');
        // } else {
        //     e.target.classList.add('muted');
        // }
        // if (video) {
        //     if (video.muted) {
        //         video.muted = false;
        //     } else {
        //         video.muted = true;
        //     }
        // }
        // const getVideo = document.querySelector(
        //     `[data-content-id="video-${id}"]`
        // );
        const videoList = document.querySelectorAll('.feed-content video');
        if (videoList) {
            videoList.forEach((video) => {
                video.muted = !muted;
            });
        }
        props.muteToggleHandler();
    };

    const onFeedArrowClick = (index, item) => {
        console.log('arrow click');
    };
    const openInterestPopup = (feed_id) => {
        setOpenInterestDialog(true);
        setInterestFormData({ ...interestFormData, feed_id: feed_id });
    };
    const interestInputChangeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInterestFormData({ ...interestFormData, [name]: value });
    };

    const submitFeedInterest = async () => {
        // if (!interestFormData.feed_id) {
        //     alert('Feed ID is required');
        //     return;
        // }
        // if (
        //     !interestFormData?.guest_name ||
        //     !interestFormData?.guest_name?.trim()
        // ) {
        //     alert('Name is required');
        //     return;
        // }
        // if (
        //     !interestFormData?.guest_email ||
        //     !interestFormData?.guest_email?.trim()
        // ) {
        //     alert('Email is required');
        //     return;
        // }
        // if (
        //     !interestFormData?.guest_mobile ||
        //     !interestFormData?.guest_mobile?.trim()
        // ) {
        //     alert('Mobile is required');
        //     return;
        // }
        setLoading(true);
        try {
            const data = serialize(interestFormData);
            const response = await feedInterest(data);
            if (response.status === 200) {
                alert(
                    feed.interest_action_response || 'Your request has been submitted. Our Executive will reach out to you soon!'
                );
                setOpenInterestDialog(false);
            }
        } catch (error) {
            alert(t['common_error']);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <div className='feed-card'>
                <div className={`feed-body count-${feed.feed_contents?.length}`}>
                    <Carousel
                        showArrows={false}
                        showThumbs={false}
                        onChange={onFeedChange}
                        onClickItem={onFeedClick}
                        onClickThumb={onFeedArrowClick}
                    >
                        {feed.feed_contents.map((item) => (
                            <div
                                key={item.id}
                                className='feed-content'
                                data-content-type={item.content_type}
                                data-content-id={`video-${item.id}`}
                            >
                                {item.content_type === 'video' ? (
                                    <React.Fragment>
                                        <ReactPlayer
                                            url={`https://vserve-feeds.s3.ap-south-1.amazonaws.com/${item.content_src}`}
                                            muted={muted}
                                            playsinline={true}
                                        />
                                        <div
                                            className={
                                                'mute-icon ' +
                                                (muted ? 'muted' : '')
                                            }
                                            onClick={(e) =>
                                                onMuteClickHandler(e, item.id)
                                            }
                                        ></div>
                                    </React.Fragment>
                                ) : (
                                    <img
                                        src={`https://vserve-feeds.s3.ap-south-1.amazonaws.com/${item.content_src}`}
                                        alt='feed-title'
                                    />
                                )}
                                {Boolean(feed.is_pinned) && (
                                    <div className='pin-icon'>
                                        <i className='fas fa-thumbtack'></i>
                                    </div>
                                )}
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className='feed-footer'>
                    <div className='action-conainer'>
                        <div className='left-sec'>
                            <div
                                className={
                                    'btn-like ' +
                                    (feed.is_liked_by_me ? 'liked' : '')
                                }
                                onClick={(e) =>
                                    feedLikeCLickHandler(
                                        e,
                                        feed.id,
                                        feed.is_liked_by_me
                                    )
                                }
                            ></div>
                            {feed.likes_count > 0 && (
                                <div className='total-views'>
                                    {feed.likes_count}{' '}
                                    {feed.likes_count === 1 ? t['feed_like'] : t['feed_likes']}
                                </div>
                            )}
                            {feed.views_count > 0 && (
                                <div className='total-views'>
                                    {feed.views_count}{' '}
                                    {feed.views_count === 1 ? t['feed_view'] : t['feed_views']}
                                </div>
                            )}
                        </div>
                        {Boolean(feed.interest_action_btn_enabled) && <div className='right-sec'>
                            <button
                                className='btn btn-interest'
                                onClick={() => openInterestPopup(feed.id)}
                            >
                                {feed.interest_action_btn_text|| t['feed_get_offer']}
                            </button>
                        </div>}
                    </div>

                    <div className='feed-description'>
                        {/* <span className='autor'>{hotelDetails.name}</span> */}
                        {feed.description && (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: feed.description,
                                }}
                            ></span>
                        )}
                    </div>
                    <div className='feed-postdate'>
                        {feed.published_on
                            ? new Date(feed.published_on).toDateString()
                            : new Date(feed.created_at).toDateString()}
                    </div>
                </div>
            </div>
            <FeedInterestDialog
                modalIsOpen={openInterestDialog}
                onCancel={() => setOpenInterestDialog(false)}
                onConfirm={submitFeedInterest}
                confirmMsg='Enter the below details '
                interestInputChangeHandler={interestInputChangeHandler}
                interestFormData={interestFormData}
                loading={loading}
                feedCollectInfoList={feedCollectInfoList}
            />
        </>
    );
};

export default FeedCard;
