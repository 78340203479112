import { Header } from "../../components/components";

const RenderContentView = ({ html = '', closeClickHandler }) => {
    return (
        <>
            <Header showBack backBtnHandler={closeClickHandler} />
            <div className={'welcome-details-view '}>
                <div className='main-content'>
                    {html && (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: html,
                            }}
                        ></p>
                    )}
                </div>
            </div>
        </>
    );
};

export default RenderContentView;