/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { getPublicResource } from "../../services/api";

const DataProvider = (ConnectedComponent) => () => {
  const [data, setData] = useState({});
  const resource = "project-shield";
  const hotelID = localStorage.getItem("hotel_id") || false;

  useEffect(() => {
    async function getEnhancedData() {
        const langCode = localStorage.getItem('lang') || 'en';

      const {
        data: { data },
      } = await getPublicResource(
        `/guest/hotel/static-service/${resource}/${hotelID}?lang_code=${langCode}`
      );
      setData({ content: data?.content });
    }
    getEnhancedData();
  }, [hotelID]);
  return <ConnectedComponent {...data} />;
};

export default DataProvider;
