import React from 'react';
import checkmark from '../../assets/icons/right.svg';
import { useLanguage } from '../../context/LanguageContext';
import { useHotel } from '../../context/HotelContext';
import { Header } from '../../components/components';

function ThankYou() {
    const { hotelDetails } = useHotel();
    const { t } = useLanguage();

    const getThankYouMsg = () => {
        if (hotelDetails.hotel_term?.length) {
            const dataItem = hotelDetails.hotel_term.find(
                (item) => item.type === 'booking_thanks_cart'
            );
            if (dataItem) return dataItem.term;
            else return t['booking_thank_you_msg'];
        }
        return t['booking_thank_you_msg'];
    };

    return (
        <>
        <Header />
        <div className='thankyou-wrapper'>
            <img src={checkmark} alt='' />
            <p dangerouslySetInnerHTML={{ __html: getThankYouMsg() }}></p>
        </div>
        </>
    );
}

export default ThankYou;
