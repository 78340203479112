import React, { useEffect, useState } from 'react';
import veg from '../../assets/icons/veg.png';
import nonveg from '../../assets/icons/non-veg.png';
import {
    removeLocalCartItem,
    renderPrice,
    updateCartItem,
} from '../../helper/commonHelper';
import { printCurrencySymbol } from '../../helpers/commonHelper';
import { useHotel } from '../../context/HotelContext';
import { Button, Drawer } from 'antd';
import { Counter } from '../../components/components';
import { useLanguage } from '../../context/LanguageContext';

let menuItem = {};
function AddonRepeatConfirm(props) {
    const { menuItem: Mitem, open, close, updateOperationType } = props;
    const [selectedListItem, setSelectedListItem] = useState([]);
    const { hotelDetails } = useHotel();
    const currentCurrency = printCurrencySymbol(hotelDetails);
    const { t } = useLanguage();
    useEffect(() => {
        menuItem = JSON.parse(JSON.stringify(Mitem));
        // Filtering sub-addons & without-sub-addons to extract selected item
    }, [Mitem]);

    useEffect(() => {
        setSelectedItemList();
    }, []);

    function setSelectedItemList() {
        let myselectedItemList = menuItem?.cartItems?.map((item) => {
            let itemPrice = 0;
            const tempSubItem = {
                itemName: item.name,
                type: item.type,
                addons: [], // contains addon-list and sub addons list,
                itemPrice,
                itemCount: item.cart_item_count,
                cartItemId: item.cartItemId,
            };
            // adding selected addons list
            const updateTempSubItem = (addOns, title = null) => {
                addOns.forEach((addOn) => {
                    if (addOn.count === 1) {
                        const addonEle = `${
                            title ? `<span>${title}:</span>` : ''
                        }
                              <span class="addon-name"> ${addOn.name} </span >`;
                        tempSubItem.addons.push(addonEle);
                    } else if (addOn.count > 0) {
                        const addonEle = `
                         ${title ? `<span>${title}:</span>` : ''}
                         <span class="addon-name"> ${addOn.name} x ${
                            addOn.count
                        } </span >
                        `;
                        tempSubItem.addons.push(addonEle);
                    }
                    if (addOn.price > 0) {
                        itemPrice += addOn.price * addOn.count;
                    }
                });
            };

            // adding sub-addon category and without sub-addon items in a single place
            item.sub_addons?.forEach((subAdd) => {
                updateTempSubItem(subAdd.addons, subAdd.name);
            });
            updateTempSubItem(item.without_subaddon_addons);
            tempSubItem.itemPrice = itemPrice;
            return tempSubItem;
        });
        setSelectedListItem(myselectedItemList);
    }

    function updateMenuItemCount(itemId, count) {
        let menuCartItem = '';
        menuItem.cartItems = menuItem?.cartItems?.map((cartItem) => {
            if (itemId === cartItem.cartItemId) {
                cartItem.cart_item_count += count;
                menuCartItem = cartItem;
            }
            return cartItem;
        });
        // setMenuItem(menuItem)
        updateCartItem(menuCartItem);
    }

    function removeItem(item) {
        const itemId = item.cartItemId;

        if (item.itemCount === 1) {
            // Removing cart item if count is 1  //
            menuItem.cartItems = menuItem?.cartItems.filter(
                (cartItem) => itemId !== cartItem.cartItemId
            );
            // setMenuItem(menuItem)
            removeLocalCartItem(item.cartItemId);
        } else {
            // Decrementing item count is count > 1 //
            updateMenuItemCount(itemId, -1);
        }
        setSelectedItemList();
    }

    function duplicateItem(item) {
        const itemId = item.cartItemId;
        updateMenuItemCount(itemId, 1);
        setSelectedItemList();
    }
    async function addNewAddon() {
        updateOperationType({ operation: 'add', menuItem: menuItem });
    }
    return (
        <Drawer
            title={t['choose_preference']}
            className='h-48'
            onClose={close}
            open={open}
            size={'default'}
            placement='bottom'
            height={'450px'}
        >
            <div className='flex flex-col justify-start gap-4 pb-12'>
                <div className='options-wrapper'>
                    <div className='addon-repeat-confirm addonModal-wrapper'>
                        {selectedListItem?.map((item) => {
                            return (
                                <>
                                    <div className='item-food-card'>
                                        <div className='item-details-wrapper'>
                                            <div className='item-title-wrapper'>
                                                <div className='item-price'>
                                                    <div className='item-title'>
                                                        {item.type ===
                                                            'veg' && (
                                                            <div className='item-type'>
                                                                <img
                                                                    src={veg}
                                                                    alt='veg_icon'
                                                                />
                                                            </div>
                                                        )}
                                                        {item.type ===
                                                            'non_veg' && (
                                                            <div className='item-type'>
                                                                <img
                                                                    src={nonveg}
                                                                    alt='nonveg_icon'
                                                                />
                                                            </div>
                                                        )}{' '}
                                                        {item.itemName}
                                                    </div>
                                                    {item.itemPrice > 0 ? (
                                                        <div className='item-price'>
                                                            {currentCurrency}{' '}
                                                            {renderPrice(
                                                                item.itemPrice *
                                                                    item.itemCount,
                                                                hotelDetails
                                                            )}
                                                        </div>
                                                    ) : (
                                                        `  `
                                                    )}
                                                    {item.addons.length !==
                                                    0 ? (
                                                        <ul className='selectedAddonList'>
                                                            {item?.addons?.map(
                                                                (add) => {
                                                                    return (
                                                                        <li>
                                                                            <span
                                                                                className='addon-name-wrapper'
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: add,
                                                                                }}
                                                                            ></span>
                                                                        </li>
                                                                    );
                                                                }
                                                            )}
                                                        </ul>
                                                    ) : null}
                                                </div>
                                                <div className='item-actions'>
                                                    <Counter
                                                        count={item.itemCount}
                                                        increment={() =>
                                                            duplicateItem(item)
                                                        }
                                                        decrement={() =>
                                                            removeItem(item)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className='flex justify-center items-center gap-2 mt-auto absolute bg-slate-100 p-2 py-3 w-full left-0 bottom-0'>
                <Button
                    onClick={() => addNewAddon()}
                    type='primary'
                    className='w-full'
                    size='large'
                >
                    {t['add_new_customization']}
                </Button>
            </div>
        </Drawer>
    );
}

export default AddonRepeatConfirm;
