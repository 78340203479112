import React from 'react';
import styles from './loading.module.css';
import { useLanguage } from '../../context/LanguageContext';

const Loading = () => {

    const {t}=useLanguage();
    return (
        <div className={styles.loading}>
            <p>{t['common_loading']}</p>
        </div>
    );
};

export default Loading;
