import React, { useState } from 'react';
import styles from './foodAddon.module.css';
import { Checkbox } from '../components';
import { printCurrencySymbol } from '../../helpers/commonHelper';
import { renderPrice } from '../../helper/commonHelper';
import { useLanguage } from '../../context/LanguageContext';
import { useHotel } from '../../context/HotelContext';
import { Drawer } from 'antd';
import { Button } from 'antd';

const LaundryAddon = ({ foodItem, onClose, addSelected }) => {
    const [selections, updateSelection] = useState({});
    const [selectionPrice, updateSelectionPrice] = useState([]);
    const { hotelDetails } = useHotel();
    const currentCurrency = printCurrencySymbol(hotelDetails);
    const { t } = useLanguage();

    return (
        <Drawer
            title={foodItem.name}
            className='h-48'
            onClose={onClose}
            open={foodItem}
            size={'default'}
            placement='bottom'
            height={'450px'}
        >
            <div className='flex flex-col justify-start gap-4 pb-16'>
                {foodItem.sub_addons.map((addon) => {
                    const availableIds = addon.addons.map((add) => add.id);
                    const exceedingLength =
                        addon.max_addon_per_order ===
                        Object.keys(selections).filter(
                            (n) =>
                                selections[n] &&
                                availableIds.includes(parseInt(n))
                        ).length;
                    const updateAddonSelection = (option) => {
                        updateSelectionPrice((existing) => [
                            ...existing,
                            { id: option.id, price: option.price },
                        ]);
                        if (addon.type === 'single_select') {
                            let otherOptionsObject = {};
                            addon.addons.forEach((add) => {
                                otherOptionsObject[add.id] = false;
                            });
                            return updateSelection({
                                ...selections,
                                ...otherOptionsObject,
                                [option.id]: !selections[option.id],
                            });
                        }
                        return updateSelection({
                            ...selections,
                            [option.id]: !selections[option.id],
                        });
                    };
                    return !addon.enabled ? (
                        <div />
                    ) : (
                        <div className='flex flex-col justify-start gap-2'>
                            <h3>{addon.name}</h3>
                            {exceedingLength ? (
                                <p className={''}>{t['food_limit_exceeded']}</p>
                            ) : (
                                <></>
                            )}
                            {addon.addons.map(
                                (option) =>
                                    option.enabled === 1 && (
                                        <div className={'pl-3'}>
                                            <Checkbox
                                                type={
                                                    addon.type ===
                                                    'single_select'
                                                        ? 'radio'
                                                        : 'checkbox'
                                                }
                                                styleType='addon'
                                                label={option.name}
                                                labelDescription={
                                                    option.description
                                                }
                                                value={
                                                    selections[option.id] ||
                                                    false
                                                }
                                                onChange={
                                                    !selections[option.id] &&
                                                    exceedingLength
                                                        ? () => null
                                                        : () =>
                                                              updateAddonSelection(
                                                                  option
                                                              )
                                                }
                                                id={option.id}
                                            />
                                            {option.price ? (
                                                <span>
                                                    {currentCurrency}{' '}
                                                    {renderPrice(
                                                        option.price,
                                                        hotelDetails
                                                    )}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    )
                            )}
                        </div>
                    );
                })}
            </div>
            <div className='flex justify-center items-center gap-2 mt-auto absolute bg-slate-100 p-2 py-3 w-full left-0 bottom-0'>
                <Button
                    onClick={() =>
                        addSelected(foodItem.quantity || 1, {
                            ...foodItem,
                            addonPrice: selectionPrice.find(
                                (item) =>
                                    item.id ==
                                    Object.keys(selections).filter(
                                        (n) => selections[n]
                                    )[0]
                            ),
                            addons: selections,
                        })
                    }
                    type='primary'
                    className='w-full bg-theme'
                    size='large'
                >
                    {t['Add']}
                </Button>
            </div>
        </Drawer>
    );
};

export default LaundryAddon;
