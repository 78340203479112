export default {
    'year': {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
    'month': {
        format: 'M',
        caption: 'Month',
        step: 1,
    },
    'date': {
        format: 'D',
        caption: 'Day',
        step: 1,
    },
    min: new Date(),
    max: new Date(new Date().getTime() + 72 * 60 * 60 * 1000)  
}