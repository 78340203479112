import React from 'react';

import { Button } from 'antd';
import { SPA_IMAGE_ROOT_URL } from '../../services/api';
import { printCurrencySymbol } from '../../helpers/commonHelper';
import { useLanguage } from '../../context/LanguageContext';
import { renderPrice } from '../../helper/commonHelper';
import { useHotel } from '../../context/HotelContext';

const SpaCard = ({
    name,
    price,
    description,
    onClick,
    view_only,
    thumbnail,
}) => {
    const { hotelDetails } = useHotel();

    const currentCurrency = printCurrencySymbol(hotelDetails);
    const { t } = useLanguage();

    return (
        <article className='spaCard border border-gray-300'>
            {thumbnail ? (
                <img
                    className='spa_banner'
                    src={SPA_IMAGE_ROOT_URL + 'spa-items/' + thumbnail}
                    alt='spa_subcategories'
                    style={{ width: '100%' }}
                />
            ) : (
                ''
            )}
            <div className='title_book_wrapper'>
            <p>{name}</p>
                {view_only && price !== 0 && (
                    <h6 className='price'>
                        {currentCurrency} {renderPrice(price, hotelDetails)}
                    </h6>
                )}

                {!view_only && (
                    <Button type='default' onClick={onClick} style={{borderColor:'#255e71',color:'#255e71'}}>
                        {t['spa_request']}
                    </Button>
                )}
            </div>
            {description && (
                <p
                    className='description'
                    dangerouslySetInnerHTML={{ __html: description }}
                ></p>
            )}
            {!view_only && price !== 0 && (
                <h6 className='price'>
                    {currentCurrency} {renderPrice(price, hotelDetails)}
                </h6>
            )}
        </article>
    );
};

export default SpaCard;
