import React, { useState } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { Drawer } from 'antd';
import { Button } from 'antd';
function ConfirmDialog({
    modalIsOpen,
    onCancel,
    onConfirm,
    confirmMsg,
    disclaimerMsg,
}) {
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const { t } = useLanguage();
    return (
        <Drawer
            title={t['checkout_booking_details']}
            className='h-48'
            onClose={onCancel}
            open={modalIsOpen}
            size={'default'}
            placement='bottom'
            height={'450px'}
        >
            <div className='flex flex-col justify-start gap-4 pb-12'>
                <div className='main-content'>
                    {confirmMsg ? (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: confirmMsg,
                            }}
                        ></p>
                    ) : (
                        ''
                    )}

                    {disclaimerMsg ? (
                        <div className='terms-wrappper'>
                            <input
                                type='checkbox'
                                onChange={(e) =>
                                    setIsCheckboxChecked(
                                        e.currentTarget.checked
                                    )
                                }
                                id='checkbox-booking-confirm'
                                checked={isCheckboxChecked}
                            />
                            <label
                            htmlFor='checkbox-booking-confirm'
                            className='label'
                                dangerouslySetInnerHTML={{
                                    __html: disclaimerMsg,
                                }}
                            ></label>
                        </div>
                    ) : null}
                </div>
                </div>
                <div
                    className='flex justify-center items-center gap-2 mt-auto absolute bg-slate-100 p-2 py-3 w-full left-0 bottom-0'
                >
                    <Button onClick={onCancel} className='bg-theme'>{t['common_cancel']}</Button>
                    <Button
                        type='primary'
                        disabled={disclaimerMsg && !isCheckboxChecked}
                        onClick={() => onConfirm()}
                        className='bg-theme'
                    >
                        {t['common_confirm']}
                    </Button>
                </div>
        </Drawer>
    );
}

export default ConfirmDialog;
