import React, { useEffect, useState } from 'react';
import { fetchWelcomeSubsectionResponse } from '../../services/api';
import { Header } from '../../components/components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RenderContentView from './RenderContent';

const WelcomeDetails = () => {
    const [subsectionResponse, setSubsectionResponse] = useState({});
    const [subsectionList, setSubsectionList] = useState([]);
    const [contentView, setContentView] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const sectionid = searchParams.get('sectionid') || '';
    useEffect(() => {
        fetchWelcomeData(sectionid);
    }, [sectionid]);

    const fetchWelcomeData = async (sectionid) => {
        try {
            const response = await fetchWelcomeSubsectionResponse(sectionid);
            if (response.status === 200) {
                const result = response.data.data || {};
                setSubsectionResponse(result);
                if (result.sections?.length > 0) {
                    setSubsectionList(result.sections);
                } else {
                    setContentView(result.description || '');
                }
            }
            console.log(response);
        } catch (error) {}
    };

    const sectionTileClickHandler = (content) => {
        setContentView(content);
    };
    const closeClickHandler = () => {
        if (subsectionList.length > 0) {
            setContentView(null);
        } else {
            navigate(-1);
        }
    };
    return (
        <>
            {!contentView && (
                <>
                    <Header showBack />
                    <div className={'welcome-details-view '}>
                        {subsectionResponse?.parent_welcome_section?.banner && (
                            <img
                                src={`https://master-vserve.s3.ap-south-1.amazonaws.com/hotel_welcome/${encodeURIComponent(
                                    subsectionResponse.parent_welcome_section
                                        .banner
                                )}`}
                                alt=''
                                className='welcome-cover-img'
                            />
                        )}
                        <div className='subsection-wrapper'>
                            {subsectionList.map((item) => (
                                <div
                                    className='welcome-card-new'
                                    style={{
                                        backgroundColor: `${
                                            item.backgroud_color || '#ececec'
                                        }`,
                                    }}
                                    onClick={() =>
                                        sectionTileClickHandler(item?.content)
                                    }
                                >
                                    <div
                                        style={{
                                            color: `${
                                                item?.font_color || '#000000'
                                            }`,
                                        }}
                                    >
                                        {item?.name || ''}
                                    </div>
                                    {item?.description && (
                                        <p
                                            className='welcome-description'
                                            style={{
                                                color: `${
                                                    item?.font_color ||
                                                    '#1b1b19'
                                                }`,
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: item?.description || '',
                                            }}
                                        ></p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
            {contentView && (
                <RenderContentView
                    html={contentView}
                    closeClickHandler={closeClickHandler}
                />
            )}
        </>
    );
};

export default WelcomeDetails;


