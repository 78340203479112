import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// import { initialState } from "../initialState";
//
const initialState = {
    loading: false,
    menuItems: [],
    selectedMenu: {},
};
const irdSlice = createSlice({
    name: 'ird',
    initialState,
    reducers: {
        updateLoading(state, { payload }) {
            return { ...state, loading: payload };
        },
        updateMenuItems(state, { payload }) {
            return { ...state, menuItems: payload };
        },
        updateSelectedMenu(state, { payload }) {
            return { ...state, selectedMenu: payload };
        },
    },
});
export const { updateLoading, updateMenuItems, updateSelectedMenu } =
    irdSlice.actions;

export const irdSelector = (state) => state.irdSlice;
export default irdSlice.reducer;

function getHotelID() {
    const hotelID = localStorage.getItem('hotel_id');
    if (hotelID) return hotelID;
    return '';
}

export function fetchMenuItems(id) {
    return async (dispatch) => {
        dispatch(updateLoading(true));
        try {
            const langCode = localStorage.getItem('lang') || 'en';
            const menuOnlyResponse = await axios.get(
                `ird/guest/menus-only/${getHotelID()}?lang_code=${langCode}`
            );
            let categoriesResponse = await axios.get(
                `ird/guest/menu/categorise/${getHotelID()}/${id}?lang_code=${langCode}`
            );
            if (menuOnlyResponse.status === 200) {
                const data = menuOnlyResponse.data.data.all || [];
                const getMenu = data.find((item) => item.id == id) || {};
                if(getMenu && Object.keys(getMenu).length > 0 ){
                    dispatch(updateSelectedMenu(getMenu));
                }
            }
            if (categoriesResponse.status === 200) {
                const categoriesList =
                    categoriesResponse.data.data.menu_categories || [];
                dispatch(updateMenuItems(categoriesList));
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}
