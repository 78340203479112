import React from 'react';

const Card = ({ cover, heading, jumboStyle }) => {
    return (
        <article className={`card ${jumboStyle ? 'jumboCard' : ' '}`}>
            {cover && <img className='cover' src={cover} alt={heading} />}
            {heading && (
                <div className='headingWrapper'>
                    <h3 className='card-heading'>{heading}</h3>
                </div>
            )}
        </article>
    );
};

export default Card;
