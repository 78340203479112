import React, { useEffect } from 'react';

import provider from './data.provider';
import { Header, Loading } from '../../components/components';
import ReactGA from 'react-ga4';
import { useLanguage } from '../../context/LanguageContext';

const Page = ({ content = '' }) => {
    const { lang } = useLanguage()
    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
            language: lang,
        });
    }, []);

    if (!content) {
        return <Loading />;
    }

    return (
        <>
            <Header showBack />
            <div className={'static-content-container'}>
                <div
                    className={'content-wrapper'}
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </div>
        </>
    );
};

export default provider(Page);
