import React from 'react';
import { Drawer } from 'antd';

const Disclaimer = ({ disclaimerText, onClose, open, title = '' }) => {
    return (
        <Drawer
            className='h-48'
            onClose={onClose}
            open={open}
            size={'default'}
            placement='bottom'
            height={'450px'}
            title={title}
        >
            <div className='main-content'>
                {disclaimerText && (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: disclaimerText,
                        }}
                    ></p>
                )}
            </div>
        </Drawer>
    );
};

export default Disclaimer;
