/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react"
import { getPrivateResource } from "../../services/api"

const DataProvider = (ConnectedComponent) => () => {
    const [data, setData] = useState({})
    const hotelID = localStorage.getItem("hotel_id") || false;

  useEffect(() => {
    async function getEnhancedData() {
        const langCode = localStorage.getItem('lang') || 'en';

      const { data: { data }} = await getPrivateResource(`/sanitarium/spa/guest/menu/${hotelID}?lang_code=${langCode}`);
        setData({
          items: data
         })
         localStorage.setItem('laundrySpa',data?.[0]?.disclaimer)
  }
    getEnhancedData()
  }, [hotelID])
  return <ConnectedComponent {...data} />
}

export default DataProvider
