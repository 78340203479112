export  const convertTimeUTC= (userDate) =>{
    let now,nowUtc, day, month, year, minutes, hours;
    if(userDate && userDate != " "){
      now = new Date(userDate);
      nowUtc = new Date( now.getTime() + (now.getTimezoneOffset() * 60000));
      nowUtc = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
      day = '' + (nowUtc.getDate());
      month = '' + (nowUtc.getMonth() + 1);
      year = nowUtc.getFullYear();
      minutes = '' + nowUtc.getMinutes();
      hours = '' + nowUtc.getHours();
      if (month.length < 2) month = 0 + month;
      if (day.length < 2) day = 0 + day;
      if (minutes.length < 2) minutes = 0 + minutes;
      if (hours.length < 2) hours = 0 + hours;
      console.log(`${year}-${month}-${day} ${hours}:${minutes}:00`);
      return `${year}-${month}-${day} ${hours}:${minutes}:00`;
    }else{
      console.log('Invalid date and time');
      return false;
    }
  }
