import React from "react";
import styles from "./thankYou.module.css";

import checkmark from "../../assets/icons/right.svg";
import { useLanguage } from "../../context/LanguageContext";
import { useHotel } from "../../context/HotelContext";
import { Header } from "../../components/components";

const ThankYou = () => {
  const { hotelDetails } = useHotel();
  const {t}=useLanguage();

  const getThankYouMsg = () => {
    if (hotelDetails.hotel_term?.length) {
      const dataItem = hotelDetails.hotel_term.find(
        (item) => item.type === "connect_thanks_cart"
      );
      if (dataItem) return dataItem.term;
      else return t['thank_you_place_request'];
    }
    return t['thank_you_place_request'];
  };

  return (
    <>
    <Header />
    <div className={styles.laundryConfirmation}>
      <img src={checkmark} alt="" />
      <p dangerouslySetInnerHTML={{ __html: getThankYouMsg() }}></p>
    </div>
    </>
  );
};

export default ThankYou;
