import React from 'react';
import { useLanguage } from '../context/LanguageContext';
import { FLAG_LIST } from '../services/Typecode';
import { Select } from 'antd';
const Language = () => {
    const { langList, changeLanguage,lang } = useLanguage();

    const formattedLang = langList.map((item) => ({
        label: `${
            FLAG_LIST[item.lang_code.toUpperCase()]
        } ${item.lang_code.toUpperCase()}`,
        value: item.lang_code,
    }));
    return (
        <>
            <Select
                defaultValue='en'
                style={{ width: 80,borderRadius:'0.5rem!important' }}
                options={formattedLang}
                value={lang}
                className='rounded-sm'
                onChange={(val) => changeLanguage(val,true)}
            />
            {/* {langList.map((item, index) => (
                <div
                    key={item.id}
                    className={
                        'flex flex-row justify-start items-center p-2 rounded-xl mb-3 bg-slate-200' +
                        (index === langList.length ? ' mb-6 ' : ' ')
                    }
                >
                    <div className='w-6 h-6 rounded-full justify-center items-center mr-2'>
                        <div className='flex font-medium text-black text-sm justify-center items-center'>
                            {FLAG_LIST[item.lang_code.toUpperCase()]}
                        </div>
                    </div>
                    <div className=' text-black text-xs'>
                        {item.lang_code.toUpperCase()} - {item.lang_name}
                    </div>
                </div>
            ))} */}
        </>
    );
};

export default Language;
