import React from 'react';
import './modal.css';
import { Drawer } from 'antd';
import { Button } from 'antd';
const ModalTNC = ({
    title,
    body,
    onClose,
    onSuccess,
    btnSuccess,
    btnBack,
    open,
    popupHeight = '450px',
}) => {
    return (
        <Drawer
            title={title}
            className='h-48'
            onClose={onClose}
            open={open}
            size={'default'}
            placement='bottom'
            height={popupHeight}
        >
            <div className='flex flex-col justify-start gap-4 pb-16'>
                <div className='main-content'>
                    {body ? (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: body,
                            }}
                        ></p>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <div
                div
                className='flex justify-center items-center gap-2 mt-auto absolute bg-slate-100 p-2 py-3 w-full left-0 bottom-0'
            >
                <Button onClick={onClose}>{btnBack}</Button>
                <Button type='primary' onClick={onSuccess} className='bg-theme'>
                    {btnSuccess}
                </Button>
            </div>
        </Drawer>
    );
};

export default ModalTNC;
