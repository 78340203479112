import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IMAGE_ROOT_URL } from '../../services/api';
import { useHotel } from '../../context/HotelContext';
import { Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Disclaimer } from '../components';
import arrowBackIcon from '../../assets/icons/arrow-back-icon.svg'

const Header = (props) => {
    const { hotelDetails } = useHotel();
    const navigate = useNavigate();
    const { showBack, popupData, backBtnHandler, disclaimerText = '' } = props;

    const [isDisclaimer, setisDisclaimer] = useState(false);

    const openDisclaimer = () => setisDisclaimer(true);
    const closeDisclaimer = () => setisDisclaimer(false);
    return (
        <div
            className='header-new border-b border-primaryBorder flex items-center w-full sticky top-0 bg-white z-10'
            style={{ direction: 'ltr' }}
        >
            <div className='flex items-center justify-between pl-2 pr-2 w-full h-full py-1'>
                <div className='header-container flex items-center gap-4 h-full justify-between w-full'>
                    {showBack && (
                        // <Button
                        //     shape='circle'
                        //     icon={<ArrowLeftOutlined />}
                        //     className='shadow-sm'
                        //     onClick={
                        //         backBtnHandler
                        //             ? backBtnHandler
                        //             : () => navigate(-1)
                        //     }
                        // ></Button>

                        <div
                            className='arrow-back-icon'
                            onClick={
                                backBtnHandler
                                    ? backBtnHandler
                                    : () => navigate(-1)
                            }
                        >
                            <img 
                                src={arrowBackIcon} 
                                alt="Language" 
                                className="language-icon"
                            />
                        </div>
                    )}
                    <img
                        onClick={() => navigate('/')}
                        src={IMAGE_ROOT_URL + hotelDetails?.logo}
                        alt={`${hotelDetails?.name} logo`}
                        className={'w-full h-full object-contain max-w-28'}
                    />

                    {popupData ? (
                        <div className='disclaimer-icon' onClick={openDisclaimer}>
                            <InfoCircleOutlined  style={{fontSize:'22px',color:'rgb(203 201 199)'}}/>
                        </div>
                    ) : (
                        <div className='w-6 h-6'></div>
                    )}
                </div>
            </div>
            <Disclaimer
                onClose={closeDisclaimer}
                open={isDisclaimer}
                disclaimerText={disclaimerText}
            />
        </div>
    );
};

export default Header;
