import React from 'react';
import styles from './checkbox.module.css';

const Checkbox = ({
    id,
    name,
    value,
    label,
    labelDescription,
    styleType,
    type,
    onChange,
}) => {
    return (
        <div
            className={
                'custom-checkbox ' +
                (styleType === 'addon'
                    ? styles.checkboxAddonWrapper
                    : styles.checkboxWrapper)
            }
        >
            <input
                type={type}
                id={id}
                name={name}
                checked={value}
                onChange={onChange}
            />
            <label htmlFor={id}>
                {' '}
                {label}{' '}
                {labelDescription && (
                    <>
                        <br />
                        <span
                            dangerouslySetInnerHTML={{
                                __html: labelDescription,
                            }}
                        ></span>
                    </>
                )}
            </label>
        </div>
    );
};

export default Checkbox;
