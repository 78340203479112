import React from "react";
import LS from "../../services/cart";
import {
  postPrivateResourceFormData,
  postPrivateResource,
} from "../../services/api";
import { convertJSONtoFormData } from "../../helper/commonHelper";

export const FoodProvider = (ConnectedComponent) => () => {
  const sessionID = LS.initiate();
  const cartContent = LS.get(sessionID);
  const hotelID = localStorage.getItem("hotel_id") || false;
  let roomNumber = localStorage.getItem("room_no") || "";

  const submitRequest = async (
    attachment,
    { entries, guests, instructions, paymentStatus, payment_mode_id , preorder_on}
  ) => {
    let signKey = paymentStatus === "done" ? "payment_receipt" : "signature";
    const oderDataObj= {
      enc_hotel_id: hotelID,
      room_no: localStorage.getItem("room_no") || "",
      items: JSON.stringify(entries),
      no_of_guest: guests,
      payment_status: paymentStatus,
      description: instructions,
      [signKey]: attachment,
    }
    // oderDataObj[attachment.type] = attachment.file;

    if(payment_mode_id){
      oderDataObj["payment_mode_id"] = payment_mode_id;
    }
    if(preorder_on){
      oderDataObj["preorder_on"] = preorder_on;
    }
    const payload = convertJSONtoFormData(oderDataObj);
    await postPrivateResourceFormData(
      "/ird/guest/order",
      payload,
    );
  };

  return (
    <ConnectedComponent
      submitRequest={submitRequest}
      defaultEntries={cartContent}
      roomNumber={roomNumber}
    />
  );
};

export const LaundryProvider = (ConnectedComponent) => () => {
  const hotelID = localStorage.getItem("hotel_id") || false;
  const roomNumber = localStorage.getItem("room_no") || "";

  const placeOrder = async ({ pickup, items,deliveryType,instructions }) => {
    const datObj = {
        enc_hotel_id: hotelID,
        description: instructions,
        room_no: localStorage.getItem("room_no") || "",
        requested_pickup_at: pickup,
        delivery_type_id: deliveryType,
        items: JSON.stringify(items),
    }
    const payload = convertJSONtoFormData(datObj);

    await postPrivateResourceFormData("/wash/laundry/guest/order", payload);
  };

  return (
    <ConnectedComponent
      placeOrder={placeOrder}
      roomNumber={roomNumber}
    />
  );
};

export const SpaProvider = (ConnectedComponent) => () => {
  const hotelID = localStorage.getItem("hotel_id") || false;
  const roomNumber = localStorage.getItem("room_no") || "";

  const placeOrder = async ({ schedule, item, guestNumber,instructions }) => {
    const datObj = {
        enc_hotel_id: hotelID,
        description: instructions,
        room_no: localStorage.getItem("room_no") || "",
        request_schedule_at: schedule,
        items: JSON.stringify([item]),
        no_of_guest: guestNumber,
    }
    const payload = convertJSONtoFormData(datObj);
    await postPrivateResourceFormData("/sanitarium/spa/guest/order", payload);
  };

  return (
    <ConnectedComponent
      placeOrder={placeOrder}
      roomNumber={roomNumber}
    />
  );
};

export const PlaceRequestProvider = (ConnectedComponent) => () => {
  const hotelID = localStorage.getItem("hotel_id") || false;
  const roomNumber = localStorage.getItem("room_no") || "";
  const placeOrder = async ({ items, guestNumber }) => {
    const datObj = {
        enc_hotel_id: hotelID,
      room_no: localStorage.getItem("room_no") || "",
      items: JSON.stringify(items),
      no_of_guest: guestNumber,
    }
    const payload = convertJSONtoFormData(datObj);
    await postPrivateResource("/connect/guest/order", payload);
  };
  return (
    <ConnectedComponent
      placeOrder={placeOrder}
      roomNumber={roomNumber}
    />
  );
};