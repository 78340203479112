import React, { useState, useEffect } from 'react';
import { Header } from '../../components/components';
import styles from './placeRequest.module.css';
import { useLanguage } from '../../context/LanguageContext';
import { useHotel } from '../../context/HotelContext';
import { getConnectData, placeRequestOrder } from '../../services/api';
import { Button, Checkbox, Drawer } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import {
    CloseCircleFilled,
    MinusOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import {
    convertJSONtoFormData,
    formattedSelectedItems,
} from '../../helper/commonHelper';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import RoomNumber from '../../components/RoomNumber';
import { notification } from 'antd';

const updateMessageOrCount = ({
    connectData,
    itemID,
    categoryType,
    type,
    count,
    message,
}) => {
    if (categoryType === 'category') {
        connectData.categories.forEach((category) => {
            category.items.forEach((item) => {
                if (item.id === itemID && type === 'with-count') {
                    if (count === 0) {
                        item['count'] = count;
                        item['checked'] = false;
                    } else {
                        item['count'] = count;
                    }
                }
                if (item.id === itemID && type === 'open-msg') {
                    item['message'] = message;
                }
            });
        });
    } else {
        connectData.without_category_items.forEach((item) => {
            if (item.id === itemID && type === 'with-count') {
                if (count === 0) {
                    item['count'] = count;
                    item['checked'] = false;
                } else {
                    item['count'] = count;
                }
            }
            if (item.id === itemID && type === 'open-msg') {
                item['message'] = message;
            }
        });
    }
    return JSON.parse(JSON.stringify(connectData));
};
const markItemAsSelected = ({ itemID, connectData, categoryType, checked }) => {
    if (categoryType === 'category') {
        connectData.categories.forEach((category) => {
            category.items.forEach((item) => {
                if (item.id === itemID) {
                    item['checked'] = checked;
                    item['count'] = 1;
                }
            });
        });
    } else {
        connectData.without_category_items.forEach((item) => {
            if (item.id === itemID) {
                item['checked'] = checked;
                item['count'] = 1;
            }
        });
    }
    return JSON.parse(JSON.stringify(connectData));
};
const PlaceRequest = () => {
    const [activeCategoryID, setActiveCategoryID] = useState(null);
    const [connectData, setConnectData] = useState({});
    const [showReviewPopup, setShowReviewPopup] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const { t } = useLanguage();

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (connectData) {
            const itemsD = formattedSelectedItems(connectData);
            setSelectedItems(itemsD);
        }
    }, [connectData]);

    const fetchData = async () => {
        try {
            const response = await getConnectData();
            if (response.status === 200) {
                const result = response.data.data || [];
                if (result.length > 0) {
                    setConnectData(result[0]);
                }
            }
        } catch (error) {}
    };
    const handleUserSelection = ({ e, item, categoryType }) => {
        const checked = e.target.checked;
        const updatedData = markItemAsSelected({
            itemID: item.id,
            connectData,
            type: item.type,
            categoryType,
            checked,
        });
        setConnectData(updatedData);
    };

    const handleCategoryToggle = (id) => {
        if (id === activeCategoryID) {
            setActiveCategoryID(null);
        } else {
            setActiveCategoryID(id);
        }
    };

    const ChangeCountOrMessage = ({ item, count, message, categoryType }) => {
        const updated = updateMessageOrCount({
            connectData,
            itemID: item.id,
            categoryType: categoryType,
            type: item.type,
            count,
            message,
        });
        setConnectData(updated);
    };

    const continueClickHandler = () => {
        let emptyMsg = selectedItems.findIndex(
            (item) => item.type === 'open-msg' && !item.description
        );
        let emptyCount = selectedItems.findIndex(
            (item) => item.type === 'with-count' && item.count <= 0
        );
        if (emptyMsg > -1) {
            api.open({
                description: 'Enter a message in the box for selected item',
                icon: <CloseCircleFilled style={{ color: '#FF0000' }} />,
            });
            return;
        }
        if (emptyCount > -1) {
            api.open({
                description: 'Enter count for selected item',
                icon: <CloseCircleFilled style={{ color: '#FF0000' }} />,
            });
            return;
        }
        setShowReviewPopup(true);
    };

    return (
        <>
            {contextHolder}
            <Header showBack />
            <div className={styles.placeRequestLayout}>
                {connectData.image && (
                    <img
                        className={styles.banner}
                        src={`https://master-vserve.s3.ap-south-1.amazonaws.com/connects/${connectData.image}`}
                        alt='Menu Banner'
                    />
                )}

                {connectData?.categories?.map((category) => {
                    return (
                        <div className='categories-wrapper' key={category.id}>
                            {category.image && (
                                <div
                                    className='category-img'
                                    style={{
                                        backgroundImage: `url(https://master-vserve.s3.ap-south-1.amazonaws.com/connect-categories/${encodeURIComponent(
                                            category.image
                                        )}`,
                                    }}
                                ></div>
                            )}
                            <div
                                className='category-content'
                                onClick={() =>
                                    handleCategoryToggle(category.id)
                                }
                            >
                                <span>
                                    <div className='category-title'>
                                        {category.name}
                                    </div>
                                    {category.description && (
                                        <div
                                            className='category-desciption'
                                            dangerouslySetInnerHTML={{
                                                __html: category.description,
                                            }}
                                        ></div>
                                    )}
                                </span>
                                <span>
                                    <i
                                        className={
                                            'fa ' +
                                            (activeCategoryID === category.id
                                                ? 'fa-angle-down'
                                                : 'fa-angle-right')
                                        }
                                    ></i>
                                </span>
                            </div>
                            <div
                                className={
                                    'category-options ' +
                                    (activeCategoryID === category.id
                                        ? 'show'
                                        : '')
                                }
                            >
                                {category?.items?.map((item) => {
                                    return (
                                        <RenderOptions
                                            key={item.id}
                                            item={item}
                                            handleUserSelection={
                                                handleUserSelection
                                            }
                                            ChangeCountOrMessage={
                                                ChangeCountOrMessage
                                            }
                                            categoryType='category'
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
                <div className='flex flex-col gap-3'>
                    {connectData?.without_category_items?.map((item) => (
                        <RenderOptions
                            key={item.id}
                            item={item}
                            handleUserSelection={handleUserSelection}
                            ChangeCountOrMessage={ChangeCountOrMessage}
                            categoryType='without_category_items'
                        />
                    ))}
                </div>
                {selectedItems.length > 0 && (
                    <div className='fixed bottom-12 bg-white py-4 px-2 w-full left-0 right-0 shadow-sm'>
                        <Button
                            classNames='flex justify-center items-center gap-2 mt-auto absolute bg-slate-100 p-2 py-3 w-full left-0 bottom-0'
                            className='w-full bg-theme'
                            size='large'
                            type='primary'
                            onClick={continueClickHandler}
                        >
                            {t['place_request_continue']}
                        </Button>
                    </div>
                )}
            </div>
            {showReviewPopup && (
                <ConfirmReviewItems
                    showReviewPopup
                    setShowReviewPopup={setShowReviewPopup}
                    selectedItems={selectedItems}
                />
            )}
        </>
    );
};

export default PlaceRequest;

const RenderOptions = (props) => {
    const { item, handleUserSelection, ChangeCountOrMessage, categoryType } =
        props;
    const { hotelDetails } = useHotel();
    const { t } = useLanguage();

    const tagColor =
        hotelDetails.id === 12 ||
        hotelDetails.id === 16 ||
        hotelDetails.id === 204 ||
        hotelDetails.id === 206 ||
        hotelDetails.id === 207 ||
        hotelDetails.id === 208 ||
        hotelDetails.id === 209 ||
        hotelDetails.id === 210 ||
        hotelDetails.id === 332 ||
        hotelDetails.id === 389
            ? ' tag-aqua'
            : hotelDetails.id === 386
            ? ' tag-bg-bronze'
            : '';
    return (
        <div
            div
            className={
                'flex justify-between items-start ' +
                (item.type === 'open-msg' ? 'flex-col gap-2' : '')
            }
        >
            <Checkbox
                checked={item.checked}
                disabled={!Boolean(item.enabled)}
                onChange={(e) => handleUserSelection({ e, item, categoryType })}
            >
                <p>{item.name}</p>
                {item.description && (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: item.description,
                        }}
                    ></p>
                )}
                {item.tags && (
                    <div className='tagcontainer'>
                        {JSON.parse(item.tags).map((tagname) => (
                            <span
                                className={`tag ${tagColor}`}
                                key={Math.random()}
                            >
                                {tagname}
                            </span>
                        ))}
                    </div>
                )}
            </Checkbox>
            {item.checked && item.type === 'with-count' && (
                <ButtonGroup size='small'>
                    <Button
                        disabled={item.count <= 0}
                        icon={<MinusOutlined />}
                        onClick={() =>
                            ChangeCountOrMessage({
                                item,
                                categoryType,
                                count: item.count - 1,
                            })
                        }
                        className='bg-theme'
                    />
                    <Button icon={String(item.count || '0')} />

                    <Button
                        disabled={item.count >= 10}
                        icon={<PlusOutlined />}
                        onClick={() =>
                            ChangeCountOrMessage({
                                item,
                                categoryType,
                                count: item.count + 1,
                            })
                        }
                        className='bg-theme'
                    />
                </ButtonGroup>
            )}
            {item.checked && item.type === 'open-msg' && (
                <TextArea
                    placeholder={t['place_request_no_of_bottles']}
                    onChange={(e) =>
                        ChangeCountOrMessage({
                            item,
                            message: e.target.value,
                            categoryType,
                        })
                    }
                />
            )}
        </div>
    );
};

const ConfirmReviewItems = (props) => {
    const { t,lang } = useLanguage();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [guests, updateGuests] = useState(1);
    const [verifiedRoomNumber, setVerifiedRoomNumber] = useState(null);

    const navigate = useNavigate();

    const { showReviewPopup, setShowReviewPopup, selectedItems } = props;

    const placeOrderHandler = async () => {
        setLoading(true);
        setError('');
        if (!verifiedRoomNumber) {
            setError(t['checkout_place_request_error']);
            setLoading(false);
            return;
        }
        try {
            const hotelID = localStorage.getItem('hotel_id');
            const dataObj = {
                enc_hotel_id: hotelID,
                room_no: verifiedRoomNumber,
                items: JSON.stringify(selectedItems),
                no_of_guest: guests,
            };
            const payload = convertJSONtoFormData(dataObj);
            const response = await placeRequestOrder(payload);
            if (response.status === 201) {
                ReactGA.set({ placerequestPlaced: true });
                ReactGA.event({
                    category: 'Place Request',
                    action: 'Place Request',
                });
                ReactGA.event({
                    category: 'Place Request',
                    action: 'placerequest_order_placed',
                    label: lang,
                });
                navigate('/request-thank-you', { replace: true });
            }
        } catch (e) {
            setLoading(false);
            setError(t['checkout_place_request_error']);
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Drawer
            title={t['place_request_review']}
            onClose={() => setShowReviewPopup(false)}
            open={showReviewPopup}
            size={'default'}
            placement='bottom'
            height={'500px'}
        >
            {selectedItems.length > 0 ? (
                <div className='flex flex-col justify-start gap-4 pb-24 px-4'>
                    {error && !loading && (
                        <div className='out-of-service'>
                            <div className='icon'>
                                <i className='fa fa-info-circle'></i>
                            </div>
                            <div className='text'>{error}</div>
                        </div>
                    )}
                    {selectedItems?.map((item) => {
                        return (
                            <div className='flex ' key={item.connect_item_id}>
                                {item.type === 'with-count' ? (
                                    <div className='name font-bold text-md text-black'>
                                        {item.name}- {item.quantity}
                                    </div>
                                ) : item.type === 'open-msg' ? (
                                    <div className='flex flex-col'>
                                        <div className='name font-bold text-md text-black'>
                                            {item.name}
                                        </div>
                                        <p>
                                            {item.description !== '' &&
                                                item.description}
                                        </p>
                                    </div>
                                ) : (
                                    <div className='name font-bold text-md text-black'>
                                        {item.name}
                                    </div>
                                )}
                            </div>
                        );
                    })}

                    <div className='flex justify-between items-center '>
                        <label>{t['checkout_number_of_guests']}</label>

                        <ButtonGroup>
                            <Button
                                onClick={
                                    guests === 0
                                        ? null
                                        : () => updateGuests(guests - 1)
                                }
                                icon={<MinusOutlined />}
                                className='bg-theme'
                            />
                            <Button icon={String(guests)} />

                            <Button
                                onClick={() => updateGuests(guests + 1)}
                                icon={<PlusOutlined />}
                                className='bg-theme'
                            />
                        </ButtonGroup>
                    </div>
                    <RoomNumber
                        verifiedRoomNumber
                        setVerifiedRoomNumber={setVerifiedRoomNumber}
                    />
                </div>
            ) : (
                <div className='flex justify-center items-center h-48'>
                    <Button
                        type='primary'
                        onClick={() => setShowReviewPopup(false)}
                        className='bg-theme'
                    >
                        Back to Options
                    </Button>
                </div>
            )}
            <div className='flex justify-center items-center gap-2 mt-auto absolute bg-slate-100 p-2 py-3 w-full left-0 bottom-0'>
                <Button
                    type='primary'
                    className='w-full bg-theme'
                    size='large'
                    onClick={placeOrderHandler}
                    loading={loading}
                    disabled={
                        loading || !selectedItems.length || !guests || !verifiedRoomNumber
                    }
                >
                    {t['place_request_button']}
                </Button>
            </div>
        </Drawer>
    );
};
