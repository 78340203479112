/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { Button, Counter } from '../components';
import styles from './checkoutCard.module.css';
import { printCurrencySymbol } from '../../helpers/commonHelper';
import { useLanguage } from '../../context/LanguageContext';
import { useHotel } from '../../context/HotelContext';
import { renderPrice } from '../../helper/commonHelper';
const MenuCard = ({
    name,
    price,
    addons: selectedAddons = [],
    sub_addons: availableAddons = [{}],
    inclTaxes,
    laundry = false,
    taxedPrice,
    increaseQuantity,
    decreaseQuantity,
    defaultQuantity = {
        quantity: 0,
    },
}) => {
    const [count, setCount] = useState(defaultQuantity.quantity);
    const { hotelDetails } = useHotel();
    const { t } = useLanguage();
    const currentCurrency = printCurrencySymbol(hotelDetails);
    const increment = (newQuantity) => {
        setCount(newQuantity);
        increaseQuantity(newQuantity);
        return;
    };

    const decrement = (newQuantity) => {
        setCount(newQuantity);
        decreaseQuantity(newQuantity);
        return;
    };
    const addonsWithNames = Object.keys(selectedAddons)
        .map((key) => {
            if (selectedAddons[key]) {
                let allAvailableAddons = [];
                // eslint-disable-next-line no-unused-expressions
                if (!availableAddons?.addons) {
                    availableAddons?.forEach((addonGroup) => {
                        addonGroup.addons.forEach((subAddons) => {
                            const titleWithAddon = {
                                name: addonGroup.name,
                                addons: subAddons,
                            };
                            allAvailableAddons.push(titleWithAddon);
                        });
                    });
                } else {
                    // eslint-disable-next-line no-unused-expressions
                    availableAddons.addons.forEach((subAddons) =>
                        allAvailableAddons.push(subAddons)
                    );
                }
                const item = allAvailableAddons.find(
                    (addon) => addon.addons.id == key
                );
                price += item?.addons?.price || 0;
                return item ? item : null;
            }
        })
        .filter((n) => n);
    price = price * count;
    return (
        <article className='flex flex-row mb-4 relative text-md justify-between gap-2 '>
            <div className='flex flex-col w-full'>
                <div className={'tex-sm font-semibold'}>{name}</div>
                <ul className={styles.addonList}>
                    {addonsWithNames.map((item) => (
                        <>
                            <p className={styles.addonListTitle}>{item.name}</p>
                            <li className={styles.addonListitem}>
                                <small>
                                    <b> • {item.addons.name}</b>
                                </small>
                            </li>
                        </>
                    ))}
                </ul>
                <span>
                    {currentCurrency} {renderPrice(price, hotelDetails)}
                </span>
            </div>
            <div className={styles.addButtonWrapper}>
                {count === 0 ? (
                    <Button
                        type='add'
                        label={t['ird_add_item_btn']}
                        onClick={() => [setCount(1), increment(1)]}
                        className='bg-theme'
                    />
                ) : (
                    <>
                        <Counter
                            count={count}
                            increment={() => increment(count + 1)}
                            decrement={() => decrement(count - 1)}
                        />
                        {!laundry && taxedPrice && (
                            <div>
                                <span className={styles.taxedPrice}>
                                    {currentCurrency}{' '}
                                    {renderPrice(taxedPrice, hotelDetails)}
                                </span>
                                <span className={styles.inclTaxes}>
                                    {' '}
                                    {inclTaxes}
                                </span>
                            </div>
                        )}
                    </>
                )}
            </div>
        </article>
    );
};

export default MenuCard;
